import React, { createContext, useReducer, useEffect, useState } from 'react';

const initialState = {
  cart: [],
  total: 0,
  shippingTotal: 0,
  loading: false,
  error: null,
  shareCode: null,
  sharedCart: null
};

const cardReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: true };
      
    case 'REMOVE_FROM_CARD':
      return {
        ...state,
        cart: state.cart.filter(cart => cart.cart_id !== action.payload),
        loading: false
      };

    case 'UPDATE_QUANTITY_SUCCESS':
      return {
        ...state,
        cart: state.cart.map(item =>
          item.cart_id === action.payload.cart_id
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
        loading: false
      };

    case 'CALCULATE_TOTAL':
      const totalAmount = state.cart.reduce((acc, product) => {
        return acc + (product.fiyat * (product.quantity || 1));
      }, 0);
      const shippingTotal = state.cart.reduce((acc, product) => {
        return acc + (product.kargo_fiyati || 0);
      }, 0);
      
      return { 
        ...state,  
        total: totalAmount, 
        shippingTotal: shippingTotal.toFixed(2)
      };

    case 'FETCH_CARD_SUCCESS':
      return { 
        ...state, 
        cart: action.payload,
        loading: false 
      };

    case 'FETCH_CARD_ERROR':
      return { 
        ...state, 
        error: action.payload,
        loading: false 
      };

    case 'GENERATE_SHARE_CODE_SUCCESS':
      return {
        ...state,
        shareCode: action.payload,
        loading: false
      };
    
    case 'LOAD_SHARED_CART_SUCCESS':
      return {
        ...state,
        sharedCart: action.payload,
        loading: false,
        error: null
      };
    default:
      return state;
  }
};

export const CardContext = createContext();

export const CardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cardReducer, initialState);
  const [isLoadingSharedCart, setIsLoadingSharedCart] = useState(false);
  
  const addToCard = async (product, size) => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      // API'ye size değerini sadece yüzük ürünleri için gönder
      // Size null ise API body'sinde size parametresini gönderme
      const requestBody = {
        urun_id: product,
      };
      
      // Size değeri varsa ekle
      if (size !== null && size !== undefined) {
        requestBody.size = size;
      }
      
      const response = await fetch(`https://api.bikuyum.com/api/cart`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody)
      });
      
      const data = await response.json();
      
      if (data.message) {
        fbq('track', 'AddToCart');
        await getCard();
        return true;
      } else if (data.error) {
        console.error('API hata mesajı:', data.error);
        dispatch({ type: 'FETCH_CARD_ERROR', payload: data.error });
        return false;
      }
      
      return false;
    } catch (error) {
      console.error("Error adding product to cart:", error);
      dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
      return false;
    }
  };

  const getCard = async () => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch(`https://api.bikuyum.com/api/cart`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const correctedData = data.map(cart => {
        if (typeof cart.image === 'string') {
          try {
            const imageUrls = JSON.parse(cart.image.replace(/\\/g, ''));
            return { ...cart, image: imageUrls };
          } catch (error) {
            console.error('Image JSON parse edilemedi:', error);
            return cart;
          }
        }
        return cart;
      });
      dispatch({ type: 'FETCH_CARD_SUCCESS', payload: correctedData });
    } catch (error) {
      dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
    }
  };

  const deleteCard = async (product) => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch(`https://api.bikuyum.com/api/cart?id=${product}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (data.message) {
        dispatch({ type: 'REMOVE_FROM_CARD', payload: product });
        await getCard();
      }
    } catch (error) {
      console.error("Error deleting product from cart:", error);
      dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
    }
  };

  // Sepet paylaşma fonksiyonu
  const shareCart = async () => {
    try {
      dispatch({ type: 'SET_LOADING' });
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      
      // Rastgele bir referans kodu oluştur
      const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < 8; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };
      
      const shareCode = generateRandomCode();

      const isRingProduct = (item) => {
        if (!item.kategori) return false;
        return item.kategori.includes("75") || item.kategori.includes("103") || item.kategori.includes("112");
      };
      
      const cartItems = state.cart.map(item => {
        const defaultSize = isRingProduct(item) ? 15 : 0;
        
        return {
          urun_id: item.id || item.urun_id,
          size: item.size || defaultSize,
          quantity: item.quantity || 1
        };
      });
      
      const response = await fetch(`https://api.bikuyum.com/api/cart/share`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          share_code: shareCode,
          cart_items: cartItems
        })
      });
      
      const data = await response.json();
      
      if (data.success) {
        dispatch({ type: 'GENERATE_SHARE_CODE_SUCCESS', payload: shareCode });
        return shareCode;
      } else {
        throw new Error(data.message || 'Sepet paylaşma işlemi başarısız oldu');
      }
    } catch (error) {
      console.error("Sepet paylaşma hatası:", error);
      dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
      return null;
    }
  };
  
  const loadSharedCart = async (shareCode) => {
    if (isLoadingSharedCart) {
      return false;
    }

    try {
      setIsLoadingSharedCart(true);      

      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      const response = await fetch(`https://api.bikuyum.com/api/cart/share?share_ref=${shareCode}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        }
      });
          
      if (!response.ok) {
        const errorText = await response.text();
        console.error('api Hata Yanıtı:', response.status, errorText);
        throw new Error(`Paylaşılan sepet bulunamadı (${response.status})`);
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        dispatch({ type: 'LOAD_SHARED_CART_SUCCESS', payload: data });

        document.cookie = `share_ref=${shareCode}; path=/; max-age=${60 * 60 * 2}`; // 2 saat geçerli
        
        return true;
      } else {
        throw new Error('Paylaşılan sepette ürün bulunamadı');
      }
    } catch (error) {
      console.error("Paylaşılan sepeti yükleme hatası:", error);
      dispatch({ type: 'FETCH_CARD_ERROR', payload: error.message });
      return false;
    } finally {
      setIsLoadingSharedCart(false);
    }
  };
  
  useEffect(() => {
    const checkReferralCode = async () => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith('share_ref=')) {
          const shareCode = cookie.substring('share_ref='.length, cookie.length);
          await loadSharedCart(shareCode);
          return;
        }
      }
    };
    checkReferralCode();
  }, []);

  useEffect(() => {
    getCard();
  }, []);
 
  useEffect(() => {
    if (state.cart.length > 0) {
      dispatch({ type: 'CALCULATE_TOTAL' });
    }
  }, [state.cart]);

  return (
    <CardContext.Provider value={{
      state,
      cart: state.cart,
      total: state.total,
      shippingTotal: state.shippingTotal,
      loading: state.loading,
      error: state.error,
      shareCode: state.shareCode,
      sharedCart: state.sharedCart,
      addToCard,
      deleteCard,
      shareCart,
      loadSharedCart
    }}>
      {children}
    </CardContext.Provider>
  );
};

