import React, { useState } from 'react';
import Modal from 'react-modal';
import './ShareModal.css';

const ShareModal = ({ isOpen, onClose, shareUrl, generatedShareCode }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = shareUrl;
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      
      if (successful) {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
      } else {
        navigator.clipboard.writeText(shareUrl)
          .then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 3000);
          })
          .catch(err => {
            console.error('Kopyalama hatası:', err);
          });
      }
    } catch (err) {
      console.error('Kopyalama hatası:', err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Sepet Paylaşma"
      className="share-modal"
      overlayClassName="modal-overlay"
    >
      <div className="share-modal-content">
        <h2>Sepetiniz Paylaşıma Hazır!</h2>
        <p>Aşağıdaki bağlantıyı paylaşarak sepetinizi paylaşabilirsiniz:</p>

        <div className="share-url-container">
          <input
            type="text"
            value={shareUrl}
            readOnly
            className="share-url-input"
          />
          <button
            onClick={copyToClipboard}
            className="copy-button"
          >
            {copySuccess ? 'Kopyalandı!' : 'Kopyala'}
          </button>
        </div>

        <p className="share-code-info">
          Referans Kodu: <strong>{generatedShareCode}</strong>
        </p>

        <p className="share-note">
          Not: Bu bağlantıyı alan kişiler sepetinizdeki ürünleri kendi sepetlerine ekleyebilecekler.
        </p>

        <div className="share-modal-actions">
          <button
            onClick={onClose}
            className="close-share-modal"
          >
            Kapat
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal; 