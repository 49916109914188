import React, { useState, useEffect } from 'react';

const SoldAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [visitCount, setVisitCount] = useState(() => {
    const saved = localStorage.getItem('productVisitCount');
    return saved ? parseInt(saved) : 0;
  });
  const [targetVisit] = useState(() => {
    const saved = localStorage.getItem('targetVisitCount');
    if (saved) {
      return parseInt(saved);
    }
    const newTarget = Math.floor(Math.random() * (7 - 2 + 1)) + 2; // 2-7 arası
    localStorage.setItem('targetVisitCount', newTarget.toString());
    return newTarget;
  });

  useEffect(() => {
    const checkAndUpdateVisits = () => {
      const currentCount = parseInt(localStorage.getItem('productVisitCount') || '0');
      const newCount = currentCount + 1;
      
      setVisitCount(newCount);
      localStorage.setItem('productVisitCount', newCount.toString());
  
      if (newCount >= targetVisit) {
        // sayaç sıfırlanır ve yeni hedef belirlenir
        const newTarget = Math.floor(Math.random() * (7 - 2 + 1)) + 2;

        localStorage.setItem('targetVisitCount', newTarget.toString());
        localStorage.setItem('productVisitCount', '0');
        setVisitCount(0);
        
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 5000);
      }
    };
  
    checkAndUpdateVisits();
  }, []);

  return showAlert ? (
    <div
      style={{
        position: 'fixed',
        top: '40px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.98)',
        padding: '20px 30px',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
        zIndex: 1000,
        animation: 'slideDown 0.5s ease-out',
        width: 'auto',
        minWidth: '300px',
        border: '1px solid #FFD700',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <div style={{
          fontSize: '24px',
          color: '#FFD700',
          animation: 'pulse 2s infinite'
        }}>
          ☹
        </div>

        <p style={{
          margin: 0,
          color: '#333',
          fontSize: '16px',
          fontWeight: '500',
          flex: 1
        }}>
          Son incelediğiniz ürünlerden biri satıldı!
        </p>
      </div>

      <style>
        {`
          @keyframes slideDown {
            from {
              transform: translate(-50%, -100%);
              opacity: 0;
            }
            to {
              transform: translate(-50%, 0);
              opacity: 1;
            }
          }

          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </div>
  ) : null;
};

export default SoldAlert;