import React, { createContext, useReducer } from 'react';

const initialState = {
  orders: [],
  loading: true,
  error: null,
};

const orderReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_ORDERS_SUCCESS':
      return { ...state, orders: action.payload, loading: false };
    case 'FETCH_ORDERS_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_LOADING':
      return { ...state, loading: true };
    default:
      return state;
  }
};

export const getOrderStatusMessage = (status) => {
  switch (status) {
    case 0:
      return "Ödeme Başarısız";
    case 1:
      return "Ödeme Alındı";
    case 2:
      return "Onaylanan";
    case 3:
      return "Kargoda";
    case 4:
      return "Tamamlanan";
    case 5:
      return "İptal Edilen";
    default:
      return "Bilinmeyen Durum";
  }
};

export const getOrderStatusClass = (status) => {
  switch (status) {
    case 0:
      return "failed";
    case 1:
      return "paid";
    case 2:
      return "approved";
    case 3:
      return "shipped";
    case 4:
      return "completed";
    case 5:
      return "cancelled";
    default:
      return "unknown";
  }
};

export const OrderContext = createContext();


export const OrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(orderReducer, initialState);
  
  const fetchOrders = async () => {
    dispatch({ type: 'SET_LOADING' });
    try {
      const token = localStorage.getItem('token');
    if (!token) throw new Error('Token bulunamadı');
    const tkn= localStorage.getItem('tkn');
      const response = await fetch(`https://api.bikuyum.com/api/orders`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Tkn':`${tkn}`, 
        }
      });
      if (!response.ok) {
        const errorDetails = await response.text(); 
        console.error('Hata Detayları:', errorDetails);
        throw new Error('Ağdan veri alınamadı');
      }
      const orders = await response.json();
      const modifiedOrders = orders.map(order => {
        const updatedProducts = order.products.map(product => {
          if (typeof product.resim === 'string') {
            try {
              const imageUrls = JSON.parse(product.resim.replace(/\\/g, ''));
              return { ...product, resim: imageUrls }; 
            } catch (error) {
              console.error('Image JSON parse edilemedi:', error);
              return product; 
            }
          }
          return product; 
        });
  
        let fixedDate = order.siparis_tarihi;
        const orderDate = new Date(order.siparis_tarihi);
        if (orderDate.getFullYear() < 2000 || isNaN(orderDate.getTime())) {
          if (typeof order.siparis_tarihi === 'number' && order.siparis_tarihi < 10000000000) {
            fixedDate = order.siparis_tarihi * 1000;
          } else {
            fixedDate = new Date().toISOString();
          }
        }
  
        return { ...order, siparis_tarihi: fixedDate, products: updatedProducts }; 
      });
      dispatch({ type: 'FETCH_ORDERS_SUCCESS', payload: modifiedOrders });
    } catch (error) {
      dispatch({ type: 'FETCH_ORDERS_ERROR', payload: error.message });
    }
  };

 
  

  return (
    <OrderContext.Provider value={{ ...state, getOrderStatusMessage, getOrderStatusClass, fetchOrders}}>
      {children}
    </OrderContext.Provider>
  );
};