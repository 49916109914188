import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductContext } from '../../context/ProductContext.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./ProductDetail.css";

import { CardContext } from '../../context/CardContext.js';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import Popup from '../../components/PopUp.js';
import ImageGallery from './ImageGallery.js';
import { useImage } from "../../context/ImageContext.js";
import { Helmet } from "react-helmet-async";
import Loader from '../../components/Loader.js';

import SoldAlert from '../../components/SoldAlert';
import { InstitutionalContext } from '../../context/InstitutionalContext.js';


const ProductDetails = () => {
  const { sef } = useParams();
  const { getImageUrl } = useImage();
  const { addToCard } = useContext(CardContext);
  const { state, fetchProductById, fetchStoreProduct, fetchProducts } = useContext(ProductContext);
  const { products, loading, error, productDetails, store } = state;
  const { favorites, addToFavorite, removeFavorite } = useContext(FavoriteContext);
  const [activeTab, setActiveTab] = useState('description');
  const [message, setMessage] = useState('');
  const productLimit = 5;
  const [showPopup, setShowPopup] = useState(false);
  const [selectedSize, setSelectedSize] = useState(15);
  const { institutionals, fetchInstitutional } = useContext(InstitutionalContext);

  useEffect(() => {
    const shippingCategory = "teslimat-ve-iade";
    if (!institutionals?.sef || institutionals.sef !== shippingCategory) {
      fetchInstitutional(shippingCategory);
    }
  }, [institutionals?.sef]);


  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const handleAddToCart = () => {
    if ((productDetails.kategori.includes("75") || productDetails.kategori.includes("103") || productDetails.kategori.includes("112")) && !selectedSize) {
      setMessage("Lütfen ölçü seçin");
      setShowPopup(true);
      return;
    }

    if (productDetails.kategori.includes("75") || productDetails.kategori.includes("103") || productDetails.kategori.includes("112")) {
      addToCard(productDetails.id, selectedSize);
    } else {
      addToCard(productDetails.id);
    }

    setMessage("Ürün Başarıyla Sepete Eklendi");
    setShowPopup(true);
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleContinueShopping = () => {
    setShowPopup(false);
  };

  const handleGoToCart = () => {
    window.location.href = "/card";
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (!products || products.id !== parseInt(sef)) {
      fetchProducts(sef);
    }
  }, []);

  useEffect(() => {
    if (!productDetails || productDetails.id !== parseInt(sef)) {
      fetchProductById(sef);
    }
  }, [sef]);

  useEffect(() => {
    if (!store || store.sef !== parseInt(sef)) {
      fetchStoreProduct(sef);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isFavorite = (product) => {
    return favorites && favorites.length > 0 && favorites.some(favProduct =>
      String(favProduct.urun_id) === String(product.id)
    );
  };

  const handleFavoriteToggle = async () => {
    try {
      if (isFavorite(productDetails)) {
        // Favori ID'sini bul
        const favoriteProduct = favorites.find(fav =>
          String(fav.urun_id) === String(productDetails.id)
        );
        if (favoriteProduct) {
          await removeFavorite(favoriteProduct);
        }
      } else {
        await addToFavorite(productDetails);
      }
    } catch (error) {
      console.error('Favori işlemi sırasında hata:', error);
    }
  };

  // Önerilen ürünler için ayrı bir favori işleme fonksiyonu
  const handleRecommendedFavoriteToggle = async (e, product) => {
    e.stopPropagation();
    e.preventDefault();

    try {
      if (isFavorite(product)) {
        const favoriteProduct = favorites.find(fav =>
          String(fav.urun_id) === String(product.id)
        );
        if (favoriteProduct) {
          await removeFavorite(favoriteProduct);
        }
      } else {
        await addToFavorite(product);
      }
    } catch (error) {
      console.error('Favori işlemi sırasında hata:', error);
    }
  };

  const isOutOfStock = productDetails?.stok === 0;

  if (!productDetails && loading) return <Loader />;
  if (error) return <div>Hata: {error}</div>;
  if (!loading && !productDetails) {
    return <div></div>;
  }

  return (
    <>
      <div>
        <Helmet>
          <title>BiKuyum | {productDetails ? productDetails.baslik : 'Mağaza'}</title>
        </Helmet>
      </div>
      <div className='product-detail'>
        <div className="product-detail-container">
          <div className='imgs'>
            <ImageGallery
              images={Object.values(productDetails.image).filter(image => image)}
            />
          </div>
          <div className="product-info">
            <h1>{productDetails.baslik}</h1>
            <div className='product-actions'>
              <p>Satıcı Mağaza:</p>
              <a href={`/${productDetails.magaza_sef}/all`}>
                <p>{productDetails.magaza_adi}</p>
              </a>

              <div className="favorite" onClick={handleFavoriteToggle}>
                <FontAwesomeIcon
                  icon={isFavorite(productDetails) ? SolidHeart : RegularHeart}
                  style={{
                    color: isFavorite(productDetails) ? 'red' : 'black',
                    fontSize: window.innerWidth <= 768 ? '18px' : '24px'
                  }}
                />
              </div>
            </div>
            <div className="detail-prices">
              {productDetails.fiyat && productDetails.eski_fiyat !== productDetails.fiyat ? (
                <>
                  <h2 className="detail-originall-price" style={{ textDecoration: 'line-through' }}>
                    {productDetails.eski_fiyat.toLocaleString('tr-TR')} TL
                  </h2>
                  <h2 className="detail-discountedd-price">
                    {productDetails.fiyat.toLocaleString('tr-TR')} TL
                  </h2>
                </>
              ) : (
                <h2 className="detail-originall-price">
                  {productDetails.fiyat.toLocaleString('tr-TR')} TL
                </h2>
              )}
            </div>
            <div className="payment-options">
              <span className='taksit-secenegi'>3 Taksit Seçeneği</span>
            </div>
            <p>Stok Kodu: {productDetails.stok_kodu}</p>
            {isOutOfStock && <p className='stok-bilgi'>Ürün stokta bulunmamaktadır.</p>}
            <div className='explanation'>
              <p>
                Ürünümüz <strong>{productDetails.ayar}</strong> ayar onaylanmış ve kontrol edilmiş <strong>{productDetails.gram}</strong> Gram Altındır.
                Tüm sorularınız için, bizimle
                <a
                  href={`https://api.whatsapp.com/send?phone=905441000229&text=Merhaba, ${productDetails.sef} ürününü almak istiyorum.Link: ${encodeURIComponent(window.location.href)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button target="_blank"
                    rel="noopener noreferrer"
                    className='whatsapp-info'>Whatsapp</button>
                </a>
                "tan irtibata geçebilirsiniz.
              </p>
            </div>
            {productDetails.variants && productDetails.variants.length > 0 && (
              <div className="variants">
                <div className='variant-title'>
                  <h4>Pırlanta Bilgileri</h4>
                </div>
                <ul>
                  {productDetails.variants.map((variant, index) => (
                    <div key={index} className="variants-item">
                      <div>
                        <strong>{variant.variant_title}:</strong>
                        <span> {variant.karat}</span>
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {productDetails.kategori.includes("75") || productDetails.kategori.includes("103") || productDetails.kategori.includes("112") ? (
              <div className="ring-size">
                <p>Ölçü Seçin:</p>
                <select onChange={handleSizeChange} value={selectedSize}>
                  {Array.from({ length: 25 }, (_, index) => index + 6).map(size => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </select>
                {selectedSize !== 15 && (
                  <p className="size-price-difference">
                    Seçilen ölçü için 150 TL fiyat farkı eklenmiştir.
                  </p>
                )}
              </div>
            ) : null}
            <div className="purchase-options">
              <a href="#">
                <button onClick={handleAddToCart} className='card-button'>
                  Sepete Ekle
                </button>
              </a>
              <a>
                <button
                  onClick={() => {
                    addToCard(productDetails.id, selectedSize);
                    setTimeout(() => {
                      window.location.href = "/card";
                    }, 300);
                  }}
                  className='card-button-buy'
                >
                  Hemen Satın Al
                </button>
              </a>
              <a
                href={`https://api.whatsapp.com/send?phone=905441000229&text=Merhaba, ${productDetails.sef} ürününü almak istiyorum.Link: ${encodeURIComponent(window.location.href)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button target="_blank"
                  rel="noopener noreferrer"
                  className='whatsapp'>Whatsapp ile Sipariş</button>
              </a>
              {showPopup && (
                <Popup
                  message={message}
                  onContinue={handleContinueShopping}
                  onGoToCart={handleGoToCart}
                  closePopup={closePopup}
                  duration={3000}
                />
              )}
            </div>
            <div className="seller-info">
              <div className='seller-info-logo'>
                <a href={`/${productDetails.magaza_sef}/all`}>
                  <img src={getImageUrl(productDetails.profil_fotografi)} alt="profil-fotografi" />
                </a>
              </div>
              <div className='seller-info-store'>
                <p>Satıcı Mağaza</p>
                <a href={`/${productDetails.magaza_sef}/all`}>
                  <p>{productDetails.magaza_adi}</p>
                </a>
                <a href={`/${productDetails.magaza_sef}/all`}>
                  <button className="store">Mağazayı İncele</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='tabs-container'>
          <div className="tabs-header">
            <button
              className={activeTab === 'description' ? 'active' : ''}
              onClick={() => setActiveTab('description')}
            >
              Açıklama
            </button>
            <button
              className={activeTab === 'shipping' ? 'active' : ''}
              onClick={() => setActiveTab('shipping')}
            >
              Teslimat & İade
            </button>
          </div>
          <div className='tabs-content'>
            {activeTab === 'description' && (
              <div className='tab-pane'>
                <h4>{productDetails.baslik}</h4>
                <p>{productDetails.description}</p>
                <p>
                  Tüm sorularınız için, bizimle
                  <a
                    href={`https://api.whatsapp.com/send?phone=905441000229&text=Merhaba, ${productDetails.sef} ürününü almak istiyorum.Link: ${encodeURIComponent(window.location.href)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button target="_blank"
                      rel="noopener noreferrer"
                      className='whatsapp-info'>Whatsapp</button>
                  </a>
                  'tan irtibata geçebilirsiniz.
                </p>
              </div>
            )}
            {activeTab === "shipping" && (
              <div className='tab-pane'>
                <h4>Teslimat & İade Koşulları</h4>
                {institutionals?.aciklama ? (
                  <div dangerouslySetInnerHTML={{ __html: institutionals.aciklama }} />
                ) : (
                  <p>Yükleniyor...</p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className='recommendation-container'>
          <h3>Tavsiye Ettiğimiz Ürünler</h3>
          <div className='recommendation'>
            {shuffleArray(products).slice(0, productLimit).map(product => (
              <div key={product.sef} className="recommendation-card">
                <div className="recommendation-img-container">
                  <a href={`/urun/${product.sef}`}>
                    <img src={getImageUrl(product.image.i1)} alt={product.baslik} />
                  </a>
                  <div
                    onClick={(e) => handleRecommendedFavoriteToggle(e, product)}
                    className="favorite-iconn"
                  >
                    <FontAwesomeIcon
                      icon={isFavorite(product) ? SolidHeart : RegularHeart}
                      style={{ color: isFavorite(product) ? 'red' : 'gray', fontSize: '24px' }}
                    />
                  </div>
                </div>
                <div className="recommendation-card-responsive__info">
                  <p className="recommendation-card-responsive-name">
                    <a href={`/urun/${product.sef}`}>{product.baslik}</a>
                  </p>
                  <div className="ratingg">
                    {'★'.repeat(5)}
                  </div>
                  <div className="pricee">
                    {product.fiyat && product.eski_fiyat !== product.fiyat ? (
                      <>
                        <h2 className="originall-price" style={{ textDecoration: 'line-through' }}>
                          {product.eski_fiyat.toLocaleString('tr-TR')} TL
                        </h2>
                        <h2 className="discountedd-price">
                          {product.fiyat.toLocaleString('tr-TR')} TL
                        </h2>
                      </>
                    ) : (
                      <h2 className="originall-price">
                        {product.fiyat.toLocaleString('tr-TR')} TL
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <SoldAlert />
    </>
  );
};

export default ProductDetails;