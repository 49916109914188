import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CardContext } from '../../context/CardContext';
import { useImage } from "../../context/ImageContext.js";
import Loader from '../../components/Loader';
import './SharedCart.css';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';

const SharedCart = () => {
  const { shareCode, sef } = useParams();
  const navigate = useNavigate();
  const { loadSharedCart, addToCard, loading, sharedCart } = useContext(CardContext);
  const { getImageUrl } = useImage();
  const [loadStatus, setLoadStatus] = useState({ loaded: false, error: null });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [addMessage, setAddMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [selectAll, setSelectAll] = useState(true);
  const [selectedSizes, setSelectedSizes] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  
  const { favorites, addToFavorite, removeFavorite } = useContext(FavoriteContext);

  useEffect(() => {
    const loadCart = async () => {
      if (!isInitialLoad || !shareCode) return;

      try {
        setIsInitialLoad(false);
        const success = await loadSharedCart(shareCode);
        if (success) {
          setLoadStatus({ loaded: true, error: null });
        } else {
          setLoadStatus({ loaded: false, error: 'Paylaşılan sepet yüklenemedi.' });
        }
      } catch (error) {
        console.error('Sepet yükleme hatası:', error);
        setLoadStatus({ loaded: false, error: 'Paylaşılan sepet yüklenirken bir hata oluştu.' });
      }
    };

    loadCart();
  }, [shareCode, loadSharedCart, isInitialLoad]);

  useEffect(() => {
    if (sharedCart && sharedCart.length > 0) {
      const initialSelected = {};
      const initialSizes = {};

      sharedCart.forEach((item, index) => {
        initialSelected[index] = true;
        if (isRingProduct(item)) {
          initialSizes[index] = item.size || 15;
        }
      });

      setSelectedItems(initialSelected);
      setSelectedSizes(initialSizes);
      setSelectAll(true);
      calculateTotalPrice();
    }
  }, [sharedCart]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedItems, selectedSizes]);

  const calculateTotalPrice = () => {
    if (!sharedCart || sharedCart.length === 0) return;

    let total = 0;
    sharedCart.forEach((item, index) => {
      if (selectedItems[index]) {
        let itemPrice = parseFloat(item.fiyat || 0);

        if (isRingProduct(item) && selectedSizes[index] && selectedSizes[index] !== 15) {
          itemPrice += 150;
        }

        total += itemPrice;
      }
    });

    setTotalPrice(total);
  };

  const handleContinueShopping = () => {
    navigate('/');
  };

  const toggleSelectItem = (index) => {
    setSelectedItems(prev => ({
      ...prev,
      [index]: !prev[index]
    }));

    const updatedItems = {
      ...selectedItems,
      [index]: !selectedItems[index]
    };

    const allSelected = Object.values(updatedItems).every(value => value === true);
    setSelectAll(allSelected);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    const newSelectedItems = {};
    sharedCart.forEach((_, index) => {
      newSelectedItems[index] = newSelectAll;
    });

    setSelectedItems(newSelectedItems);
  };

  const getSelectedItems = () => {
    return sharedCart.filter((_, index) => selectedItems[index]);
  };

  const isRingProduct = (item) => {
    if (item.kategori) {
      if (typeof item.kategori === 'string') {
        if (item.kategori.includes("75") ||
          item.kategori.includes("103") ||
          item.kategori.includes("112") ||
          item.kategori.toLowerCase().includes("yüzük") ||
          item.kategori.toLowerCase().includes("yuzuk")) {
          return true;
        }
      }

      if (Array.isArray(item.kategori)) {
        const isRing = item.kategori.some(kat =>
          kat.includes("75") ||
          kat.includes("103") ||
          kat.includes("112") ||
          kat.toLowerCase().includes("yüzük") ||
          kat.toLowerCase().includes("yuzuk")
        );

        if (isRing) {
          return true;
        }
      }
    }

    if (item.baslik) {
      const title = item.baslik.toLowerCase();
      if (title.includes("yüzük") ||
        title.includes("yuzuk") ||
        title.includes("alyans") ||
        title.includes("söz") ||
        title.includes("nişan")) {
        return true;
      }
    }
    return false;
  };

  const handleSizeChange = (index, size) => {
    setSelectedSizes(prev => ({
      ...prev,
      [index]: parseInt(size)
    }));
  };

  const handleAddSelectedToCart = async () => {
    const selectedProducts = getSelectedItems();
    if (!selectedProducts || selectedProducts.length === 0) {
      setAddMessage('Lütfen en az bir ürün seçin');
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      return;
    }

    setIsLoading(true);
    setAddMessage('');

    try {
      let successCount = 0;

      for (const [i, item] of selectedProducts.entries()) {
        const itemIndex = sharedCart.findIndex(cartItem => cartItem.id === item.id);
        let sizeValue = null;

        if (isRingProduct(item) && itemIndex !== -1) {
          sizeValue = selectedSizes[itemIndex] || 15;
        }

        const success = await addToCard(item.id, sizeValue);
        if (success) {
          successCount++;
        }
      }

      if (successCount === selectedProducts.length) {
        setAddMessage('Seçili ürünler başarıyla sepetinize eklendi');
        navigate('/card');
      } else if (successCount > 0) {
        setAddMessage(`${successCount} ürün sepetinize eklendi, ${selectedProducts.length - successCount} ürün eklenemedi`);
      } else {
        setAddMessage('Ürünler sepetinize eklenemedi');
      }

      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } catch (error) {
      console.error('Sepete ekleme hatası:', error);
      setAddMessage('Ürünler sepete eklenirken bir hata oluştu');
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuySelected = async () => {
    const selectedProducts = getSelectedItems();
    if (!selectedProducts || selectedProducts.length === 0) {
      setAddMessage('Lütfen en az bir ürün seçin');
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      return;
    }

    setIsLoading(true);

    try {
      let successCount = 0;

      for (const item of selectedProducts) {
        const itemIndex = sharedCart.findIndex(cartItem => cartItem.id === item.id);
        let sizeValue = null;

        if (isRingProduct(item) && itemIndex !== -1) {
          sizeValue = selectedSizes[itemIndex] || 15;
        }

        const success = await addToCard(item.id, sizeValue);
        if (success) {
          successCount++;
        }
      }

      if (successCount > 0) {
        navigate('/card');
      } else {
        setAddMessage('Ürünler sepetinize eklenemedi');
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
      }
    } catch (error) {
      console.error('Hemen satın alma hatası:', error);
      setAddMessage('İşlem sırasında bir hata oluştu');
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const isFavorite = (product) => {
    return favorites && favorites.length > 0 && favorites.some(favProduct =>
      String(favProduct.urun_id) === String(product.id)
    );
  };

  const handleFavoriteToggle = async (event, product) => {
    event.stopPropagation();
    event.preventDefault();

    try {
        if (isFavorite(product)) {
            const favoriteProduct = favorites.find(fav =>
                String(fav.urun_id) === String(product.id)
            );
            if (favoriteProduct) {
                await removeFavorite(favoriteProduct);
            }
        } else {
            await addToFavorite(product);
        }
    } catch (error) {
        console.error('Favori işlemi sırasında hata:', error);
    }
};

  if (loading) {
    return <Loader />;
  }

  if (loadStatus.error) {
    return (
      <div className="shared-cart-container">
        <div className="shared-cart-error">
          <div className="error-icon">
            <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#FF6B6B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12 8V13" stroke="#FF6B6B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.9945 16H12.0035" stroke="#FF6B6B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <h2>Sepet Bulunamadı</h2>
          <p>{loadStatus.error}</p>
          <p>Bu paylaşım linki geçersiz olabilir veya süresi dolmuş olabilir.</p>
          <button className="shared-cart-continue-btn" onClick={handleContinueShopping}>
            Alışverişe Devam Et
          </button>
        </div>
      </div>
    );
  }

  if (loadStatus.loaded && sharedCart && sharedCart.length > 0) {
    return (
      <div className="shared-cart-container">
        <div className="shared-cart-header">
          <h1>Paylaşılan Sepet</h1>
          <p>Bu sepet sizinle paylaşıldı. Ürünleri seçerek sepetinize ekleyebilirsiniz.</p>
          <div className="select-all-container">
            <label className="select-all-label">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              <span className="select-all-text">Tümünü Seç</span>
            </label>
          </div>
        </div>
        <div className="shared-cart-items">
          {sharedCart.map((item, i) => (
            <div 
              key={i} 
              className={`shared-cart-item ${isRingProduct(item) ? 'ring-product' : ''}`}
              onClick={() => toggleSelectItem(i)}
              style={{ 
                cursor: 'pointer',
                border: selectedItems[i] ? '2px solid #4CAF50' : 'none',
                backgroundColor: selectedItems[i] ? '#f0f9f0' : 'transparent',
                transition: 'border 0.2s ease-in-out, background-color 0.2s ease-in-out',
                boxShadow: selectedItems[i] ? '0 0 10px rgba(76, 175, 80, 0.5)' : '0 4px 10px rgba(0, 0, 0, 0.1)'
              }}
            >
              <div className="item-checkbox" onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedItems[i] || false}
                  onChange={() => toggleSelectItem(i)}
                />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavoriteToggle(e, item)
                }}
                className="favorite-icon-shared-cart"
              >
                <FontAwesomeIcon
                  icon={isFavorite(item) ? SolidHeart : RegularHeart}
                  style={{ color: isFavorite(item) ? 'red' : 'gray', fontSize: window.innerWidth <= 768 ? '18px' : '24px' }}
                />
              </div>
              <div className="item-image">
                <img
                  src={getImageUrl(item.image?.i1 || '')}
                  alt={item.baslik}
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                />
              </div>
              <div className="item-details">
                <div className="item-details-top">
                  <h3>{item.baslik}</h3>
                  {isRingProduct(item) && (
                    <div className="item-size-container" onClick={(e) => e.stopPropagation()}>
                      <span className="size-select-label">Ölçü:</span>
                      <select
                        className="size-select"
                        value={selectedSizes[i] || 15}
                        onChange={(e) => handleSizeChange(i, e.target.value)}
                      >
                        {Array.from({ length: 25 }, (_, index) => index + 6).map(size => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                {isRingProduct(item) && selectedSizes[i] !== 15 && (
                  <p className="size-price-difference-shared-cart">
                    Seçilen ölçü için 150 TL fiyat farkı eklenmiştir.
                  </p>
                )}
                <div className="ratingg-shared-cart">★★★★★</div>
                <p className="item-price">
                  {isRingProduct(item) && selectedSizes[i] !== 15
                    ? ((parseFloat(item.fiyat) || 0) + 150).toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
                    : (parseFloat(item.fiyat) || 0).toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="shared-cart-summary">
          <div className="summary-item-total">
            <span>Toplam Tutar:</span>
            <span className="total-price">{totalPrice.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</span>
          </div>
        </div>

        {showMessage && (
          <div className="shared-cart-message">
            <p>{addMessage}</p>
          </div>
        )}

        <div className="shared-cart-actions">
          <button
            className="shared-cart-button add-to-cart"
            onClick={handleAddSelectedToCart}
            disabled={isLoading}
          >
            {isLoading ? 'Ekleniyor...' : 'Seçili Ürünleri Sepete Ekle'}
          </button>
          <button
            className="shared-cart-button buy-now"
            onClick={handleBuySelected}
            disabled={isLoading}
          >
            {isLoading ? 'İşleniyor...' : 'Seçili Ürünleri Satın Al'}
          </button>
          <button
            className="shared-cart-button continue-shopping"
            onClick={handleContinueShopping}
          >
            Alışverişe Devam Et
          </button>
        </div>
      </div>
    );
  }

  if (loadStatus.loaded && (!sharedCart || sharedCart.length === 0)) {
    return (
      <div className="shared-cart-container">
        <div className="shared-cart-empty">
          <div className="empty-icon">
            <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001" stroke="#ccc" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H8C3.73 8 3.03 9.99 3.3 12.43L4.05 18.43C4.26 20.39 4.98 22 9 22Z" stroke="#ccc" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
          <h2>Paylaşılan Sepet Boş</h2>
          <p>Bu sepette hiç ürün bulunmuyor.</p>
          <button className="shared-cart-continue-btn" onClick={handleContinueShopping}>
            Alışverişe Devam Et
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="shared-cart-container">
      <div className="shared-cart-loading">
        <h2>Paylaşılan Sepet Yükleniyor...</h2>
        <p>Lütfen bekleyin, paylaşılan ürünler yükleniyor.</p>
      </div>
    </div>
  );
};

export default SharedCart; 