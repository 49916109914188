import React, { useEffect, useContext } from 'react';
import { OrderContext } from '../../context/OrderContext.js';
import "./OrderDetails.css";
import { useImage } from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';

const OrderDetails = () => {
  const { getImageUrl } = useImage();
  const { orders, loading, error, fetchOrders, getOrderStatusMessage, getOrderStatusClass } = useContext(OrderContext);

  useEffect(() => {
    fetchOrders();
  }, []);

  if (loading) return <Loader />;

  if (error) {
    if (error.includes('Token')) {
      return <div className="no-orders-message">Henüz siparişiniz bulunmamaktadır.</div>;
    }
    return <div className="error-message">Siparişler yüklenirken bir hata oluştu.</div>;
  }

  return (
    <div className='order-container'>
      {orders && orders.length === 0 ? (
        <p>Henüz siparişiniz yok.</p>
      ) : (
        <div className='order-card'>
          {orders.map((order) => (
            <div key={order.id} className='order-item'>
              <div className='order-info'>
                <p><strong>Sipariş Tarihi:</strong> {(() => {
                  try {
                    const orderDate = new Date(order.siparis_tarihi);
                    // Geçerli bir tarih kontrolü
                    if (isNaN(orderDate.getTime()) || orderDate.getFullYear() < 2000) {
                      return "Tarih bilgisi mevcut değil";
                    }
                    return orderDate.toLocaleDateString('tr-TR', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    });
                  } catch (error) {
                    console.error("Tarih dönüştürme hatası:", error);
                    return "Tarih bilgisi mevcut değil";
                  }
                })()}</p>
                <p><strong>Adres:</strong> {order.adres}</p>
                <p><strong>Toplam Tutar:</strong> {parseFloat(order.toplam_tutar).toFixed(2)} TL</p>
              </div>

              <div className='order-table-container'>
                {order.products && order.products.length > 0 ? (
                  <>
                    <table className='order-table'>
                      <thead>
                        <tr>
                          <th>Ürün</th>
                          <th>Ürün Adı</th>
                          <th>Adet</th>
                          <th>Fiyat</th>
                          <th>Mağaza</th>
                          <th>Durum</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.products.map((product, index) => (
                          <tr key={index} className='product-row'>
                            <td><img src={getImageUrl(product.resim.i1)} alt={product.baslik} /></td>
                            <td>{product.baslik}</td>
                            <td>{product.quantity || 1}</td>
                            <td>{parseFloat(product.fiyat).toFixed(2)} TL</td>
                            <td>{product.magaza_adi}</td>
                            <td className={`order-status ${getOrderStatusClass(product.durum)}`}>
                              {getOrderStatusMessage(product.durum)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className='mobile-product-cards'>
                      {order.products.map((product, index) => (
                        <div key={index} className='mobile-product-card'>
                          <div className='mobile-product-header'>
                            <img src={getImageUrl(product.resim.i1)} alt={product.baslik} />
                            <h3>{product.baslik}</h3>
                          </div>
                          <div className='mobile-product-details'>
                            <span className='mobile-product-label'>Adet:</span>
                            <span>{product.quantity || 1}</span>

                            <span className='mobile-product-label'>Fiyat:</span>
                            <span>{parseFloat(product.fiyat).toFixed(2)} TL</span>

                            <span className='mobile-product-label'>Mağaza:</span>
                            <span>{product.magaza_adi}</span>

                            <span className='mobile-product-label'>Durum:</span>
                            <span className={`order-status ${getOrderStatusClass(product.durum)}`}>
                              {getOrderStatusMessage(product.durum)}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <p>Ürün bilgisi bulunamadı.</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderDetails;