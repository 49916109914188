import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import axios from "axios";
import Modal from 'react-modal';
import { InstitutionalContext } from "../../context/InstitutionalContext";
import { useToast } from "../../context/ToastContext";

Modal.setAppElement('#root');

const Register = () => {
  const navigate = useNavigate();
  const [consent1, setConsent1] = useState(false);
  const [consent2, setConsent2] = useState(false);
  const [formData, setFormData] = useState({
    ad: '',
    soyad: '',
    telefon: '',
    email: '',
    sifre: '',
    sifreOnay: '',
    sozlesmeOnay: false,
  });
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  // Modal ile ilgili state'ler
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');
  const [termContent, setTermContent] = useState(null);

  // InstitutionalContext'ten fetchInstitutional fonksiyonunu al
  const { fetchInstitutional } = useContext(InstitutionalContext);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Sözleşme içeriğini getirme işlemi
  const getTermsContent = async (sef) => {
    try {
      const content = await fetchInstitutional(sef);

      if (!content || !content.baslik || !content.aciklama) {
        throw new Error('İçerik bulunamadı');
      }

      return (
        <div>
          <h2>{content.baslik}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.aciklama }} />
        </div>
      );
    } catch (error) {
      console.error('Sözleşme içeriği yüklenirken hata oluştu:', error);
      showToast('Sözleşme içeriği yüklenirken bir hata oluştu.', 'error');
      return <div>İçerik yüklenirken bir hata oluştu.</div>;
    }
  };

  // Modal içeriğini yükleme
  useEffect(() => {
    async function loadContent() {
      if (modalIsOpen && currentTerm) {
        const content = await getTermsContent(currentTerm);
        setTermContent(content);
      } else {
        setTermContent(null);
      }
    }
    loadContent();
  }, [modalIsOpen, currentTerm]);

  const onSubmit = (e) => {
    e.preventDefault();

    // Şifre kontrolü
    if (formData.sifre !== formData.sifreOnay) {
      showToast('Şifreler eşleşmiyor, lütfen kontrol ediniz.', 'error');
      return;
    }

    // Şifre uzunluğu kontrolü
    if (formData.sifre.length < 6) {
      showToast('Şifre en az 6 karakter olmalıdır.', 'error');
      return;
    }

    // Telefon format kontrolü
    if (formData.telefon.length < 10) {
      showToast('Geçerli bir telefon numarası giriniz.', 'error');
      return;
    }

    const guest = {
      name: formData.ad,
      surname: formData.soyad,
      email: formData.email,
      phone: formData.telefon,
      password: formData.sifre,
      passwordConfirm: formData.sifreOnay,
      consent: formData.sozlesmeOnay,
      consent1: consent1,
      consent2: consent2,
    }

    setIsLoading(true);

    axios
      .post('https://api.bikuyum.com/api/register', guest)
      .then((res) => {
        showToast('Hesabınız başarıyla oluşturuldu! Giriş sayfasına yönlendiriliyorsunuz.', 'success');
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((error) => {
        console.error(error);

        if (error.response && error.response.data) {
          const errorData = error.response.data;
          
          // Özel hata mesajlarını kontrol et
          if (errorData.error) {
            showToast(errorData.error, "error");
          } else if (errorData.errors) {
            const firstError = Object.values(errorData.errors)[0];
            if (Array.isArray(firstError) && firstError.length > 0) {
              showToast(firstError[0], 'error');
            } else {
              showToast('Kayıt işlemi sırasında bir hata oluştu.', 'error');
            }
          } else {
            showToast('Kayıt işlemi sırasında bir hata oluştu.', 'error');
          }
        } else {
          showToast('Sunucu ile bağlantı kurulurken bir hata oluştu.', 'error');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="main-wrapper">
      <div className="account-box">
        <div className="account-wrapper">
          <h3 className="account-title">Hesap Oluştur</h3>

          <form onSubmit={onSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="ad"
                placeholder="Ad"
                value={formData.ad}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="soyad"
                placeholder="Soyad"
                value={formData.soyad}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="tel"
                name="telefon"
                placeholder="Telefon Numarası"
                value={formData.telefon}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="email"
                name="email"
                placeholder="E-Posta Adresi"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="password"
                name="sifre"
                placeholder="Şifre"
                value={formData.sifre}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="password"
                name="sifreOnay"
                placeholder="Şifre Tekrarı"
                value={formData.sifreOnay}
                onChange={handleChange}
                required
              />
            </div>

              <div className="register-check">
                <input
                  type="checkbox"
                  id="aydinlatma-ve-riza-metni"
                  onChange={() => setConsent1(!consent1)}
                  required
                />
                <label htmlFor="aydinlatma-ve-riza-metni" className="consent-label">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('aydinlatma-ve-riza-metni');
                    setModalIsOpen(true);
                  }}>Aydınlatma ve Rıza Metni</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>
              <div className="register-check">
                <input
                  id="uyelik-sozlesmesi"
                  type="checkbox"
                  onChange={() => setConsent2(!consent2)}
                  required
                />
                <label htmlFor="uyelik-sozlesmesi" className="consent-label">
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setCurrentTerm('uyelik-sozlesmesi');
                    setModalIsOpen(true);
                  }}>Üyelik</a> sözleşmesini okudum ve kabul ediyorum.
                </label>
              </div>

            <div>
              <button className="btn" type="submit" disabled={isLoading}>
                {isLoading ? 'İşleniyor...' : 'Kayıt Ol'}
              </button>
            </div>
            <div className="account-footer">
              <p>
                Zaten bir hesabınız var mı ?{" "}
                <a href="/login">Giriş Yap</a>
              </p>
            </div>
          </form>
        </div>
      </div>

      {/* Sözleşme Modalı */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentTerm('');
          setTermContent(null);
        }}
        contentLabel="Sözleşme Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button
          className="close-button"
          onClick={() => {
            setModalIsOpen(false);
            setCurrentTerm('');
            setTermContent(null);
          }}
        >
          ×
        </button>
        {termContent || <div>Yükleniyor...</div>}
      </Modal>
    </div>
  );
};

export default Register;
