import React, { useContext, useEffect } from "react";
import { InstitutionalContext } from "../../context/InstitutionalContext";

function DistanceSalesAgreement() {
    const { institutionals, fetchInstitutional } = useContext(InstitutionalContext);
    
    useEffect(() => {
        const shippingCategory = "mesafeli-satis-sozlesmesi";
        if (!institutionals?.sef || institutionals.sef !== shippingCategory) {
            fetchInstitutional(shippingCategory);
        }
    }, [institutionals?.sef, fetchInstitutional]);
    
    return ( 
        <> 
              <div className='tab-pane'>
                <h4>Mesafeli Satış Sözleşmesi</h4>
                {institutionals?.aciklama ? (
                  <div dangerouslySetInnerHTML={{ __html: institutionals.aciklama }} />
                ) : (
                  <p>Yükleniyor...</p>
                )}
              </div>
        </>
     );
}

export default DistanceSalesAgreement;