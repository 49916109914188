import React, { useState, useContext } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import Iller from "../../il-ilce.json";
import { UserContext } from "../../context/UserContext.js";
import { useToast } from "../../context/ToastContext.js";

const AddressComponent = () => {
  const { getAddresses, addAddress, updateAddress, deleteAddress } = useContext(UserContext);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [editingAddress, setEditingAddress] = useState(null);
  const [addressForm, setAddressForm] = useState({
    id: null,
    title: "",
    ad: "",
    soyad: "",
    email: "",
    telefon: "",
    addressText: "",
    il: "",
    ilce: ""
  });
  const [iller, setIller] = useState(Iller.iller);
  const [ilceler, setIlceler] = useState([]);
  const { showToast } = useToast();

  const openAddressModal = (address = null) => {
    if (address) {
      setEditingAddress(address.id);
      setAddressForm({
        id: address.id,
        title: address.title,
        ad: address.ad,
        soyad: address.soyad,
        email: address.email,
        telefon: address.telefon,
        addressText: address.addressText,
        il: address.il || "",
        ilce: address.ilce || ""
      });

      if (address.il) {
        const secilenIl = iller.find((il) => il.il_adi === address.il);
        setIlceler(secilenIl ? secilenIl.ilceler : []);
      }
    } else {
      setEditingAddress(null);
      setAddressForm({
        id: null,
        title: "",
        ad: "",
        soyad: "",
        email: "",
        addressText: "",
        il: "",
        ilce: ""
      });
      setIlceler([]);
    }
    setShowAddressModal(true);
  };

  const closeAddressModal = () => {
    setShowAddressModal(false);
    setEditingAddress(null);
  };

  const handleAddressFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "il") {
      const secilenIl = iller.find((il) => il.il_adi === value);
      setIlceler(secilenIl ? secilenIl.ilceler : []);
      setAddressForm(prev => ({
        ...prev,
        [name]: value,
        ilce: ""
      }));
    } else {
      setAddressForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleAddressSubmit = async (e) => {
    e.preventDefault();

    try {
      if (editingAddress) {
        await updateAddress(editingAddress, addressForm);
        const updatedAddresses = await getAddresses();
        setAddresses(updatedAddresses);
        showToast("Adres başarıyla güncellendi", "success");
      } else {
        await addAddress(addressForm);
        const updatedAddresses = await getAddresses();
        setAddresses(updatedAddresses);
        showToast("Yeni adres başarıyla eklendi", "success");
      }
      closeAddressModal();
    } catch (error) {
      console.error("Adres işlemi başarısız:", error);
      showToast("Adres işlemi sırasında bir hata oluştu", "error");
    }
  };

  const handleDeleteAddress = async (id) => {
    const confirmDelete = window.confirm("Bu adresi silmek istediğinizden emin misiniz?");
    if (confirmDelete) {
      try {
        await deleteAddress(id);
        const updatedAddresses = await getAddresses();
        setAddresses(updatedAddresses);
        showToast("Adres başarıyla silindi", "success");
      } catch (error) {
        console.error("Adres silme işlemi başarısız:", error);
        showToast("Adres silinirken bir hata oluştu", "error");
      }
    }
  };

  return (
    <div className="user-addresses-container">
      <div className="address-header">
        <h3 className="user-section-title">Adreslerim <FaEdit className="user-edit-icon" /></h3>
        <div className="user-address-actions">
          <button
            className="user-add-address-button"
            onClick={() => openAddressModal()}
          >
            Yeni Adres Ekle
          </button>
        </div>
      </div>

      <div className="user-address-list">
        {addresses.length > 0 ? (
          addresses.map(address => (
            <div className="user-address-card" key={address.id}>
              <h4>{address.title}</h4>
              <p>{address.addressText}</p>
              <div className="user-address-actions">
                <button
                  className="user-edit-address-button"
                  onClick={() => openAddressModal(address)}
                >
                  Düzenle
                </button>
                <button
                  className="user-delete-address-button"
                  onClick={() => handleDeleteAddress(address.id)}
                >
                  <FaTrash size={14} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className="user-no-address-message">Henüz kayıtlı adresiniz bulunmamaktadır.</p>
        )}
      </div>

      {showAddressModal && (
        <div className="user-address-modal-overlay">
          <div className="user-address-modal">
            <h3>{editingAddress ? "Adresi Düzenle" : "Yeni Adres Ekle"}</h3>
            <form onSubmit={handleAddressSubmit} className="user-address-modal-form">
              <div className="user-form-user">
                <label>Adres Başlığı</label>
                <input
                  type="text"
                  name="title"
                  value={addressForm.title}
                  onChange={handleAddressFormChange}
                  placeholder="Örn: Ev, İş"
                  required
                />
              </div>

              <div className="user-form-row">
                <div className="user-form-user">
                  <label>Ad</label>
                  <input
                    type="text"
                    name="ad"
                    value={addressForm.ad}
                    onChange={handleAddressFormChange}
                    placeholder="Alıcı ad"
                    required
                  />
                </div>
                <div className="user-form-user">
                  <label>Soyad</label>
                  <input
                    type="text"
                    name="soyad"
                    value={addressForm.soyad}
                    onChange={handleAddressFormChange}
                    placeholder="Alıcı soyad"
                    required
                  />
                </div>
                <div className="user-form-user">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={addressForm.email}
                    onChange={handleAddressFormChange}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="user-form-user">
                  <label>Telefon</label>
                  <input
                    type="number"
                    name="telefon"
                    value={addressForm.telefon}
                    onChange={handleAddressFormChange}
                    placeholder="Telefon"
                    required
                  />
                </div>
                <div className="user-form-user">
                  <label>İl</label>
                  <select
                    name="il"
                    value={addressForm.il}
                    onChange={handleAddressFormChange}
                    required
                  >
                    <option value="">İl Seçiniz</option>
                    {iller.map((il, index) => (
                      <option key={index} value={il.il_adi}>
                        {il.il_adi}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="user-form-user">
                  <label>İlçe</label>
                  <select
                    name="ilce"
                    value={addressForm.ilce}
                    onChange={handleAddressFormChange}
                    required
                    disabled={!addressForm.il}
                  >
                    <option value="">İlçe Seçiniz</option>
                    {ilceler.map((ilce, index) => (
                      <option key={index} value={ilce.ilce_adi}>
                        {ilce.ilce_adi}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="user-form-user">
                <label>Adres</label>
                <textarea
                  name="addressText"
                  value={addressForm.addressText}
                  onChange={handleAddressFormChange}
                  placeholder="Adresinizi giriniz"
                  required
                ></textarea>
              </div>
              <div className="user-address-modal-actions">
                <button type="button" className="user-cancel-button" onClick={closeAddressModal}>
                  İptal
                </button>
                <button type="submit" className="user-save-address-button">
                  {editingAddress ? "Güncelle" : "Kaydet"}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressComponent; 