import React, { useContext, useEffect, useState, useRef } from 'react';
import { ProductContext } from '../../context/ProductContext.js';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import "./ProductList.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "./CampaignProduct.css";
import { useImage } from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';

const CampaignProducts = () => {
    const { getImageUrl } = useImage();
    const { favorites, addToFavorite, removeFavorite } = useContext(FavoriteContext);
    const { state, fetchProducts, dispatch, fetchCampaignProduct } = useContext(ProductContext);
    const { products, campaignProduct, loading, error } = state;
    const [currentPage, setCurrentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const productContainerRef = useRef(null);

    const scrollInterval = useRef(null);

    const handleProductClick = (sef) => {
        window.location.href = `/urun/${sef}`;
    };
    useEffect(() => {

        fetchCampaignProduct();

    }, []);
    const isFavorite = (product) => {
        return favorites.some(favProduct =>
            String(favProduct.urun_id) === String(product.id)
        );
    };

    const handleFavoriteToggle = async (event, product) => {
        event.stopPropagation();
        event.preventDefault();

        try {
            if (isFavorite(product)) {
                const favoriteProduct = favorites.find(fav =>
                    String(fav.urun_id) === String(product.id)
                );
                if (favoriteProduct) {
                    await removeFavorite(favoriteProduct);
                }
            } else {
                await addToFavorite(product);
            }
        } catch (error) {
            console.error('Favori işlemi sırasında hata:', error);
        }
    };


    useEffect(() => {
        const loadProducts = async () => {
            if (isFetching) return;
            setIsFetching(true);
            await fetchProducts(currentPage);
            setIsFetching(false);
        };
        loadProducts();
    }, [currentPage]);

    useEffect(() => {
        dispatch({ type: 'RESET_PRODUCTS' });
        setCurrentPage(1);
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = window.innerWidth < 768 ? 1300 : 700;
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - scrollThreshold &&
                !isFetching &&
                !loading
            ) {
                setCurrentPage(prev => prev + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isFetching, loading]);


    useEffect(() => {
        const scrollProducts = () => {
            if (productContainerRef.current) {
                const container = productContainerRef.current;
                const maxScrollLeft = container.scrollWidth - container.clientWidth;

                if (container.scrollLeft >= maxScrollLeft) {
                    container.scrollTo({ left: 0, behavior: 'smooth' });
                } else {
                    container.scrollBy({ left: 200, behavior: 'smooth' });
                }
            }
        };

        scrollInterval.current = setInterval(scrollProducts, 3000);
        return () => clearInterval(scrollInterval.current);
    }, []);


    const scrollRight = () => {
        if (productContainerRef.current) {
            productContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    const scrollLeft = () => {
        if (productContainerRef.current) {
            productContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };
    if (loading) return <Loader />;
    if (error) return <div>Hata: {error}</div>;


    return (
        <>

            <div className="campaign-container">
                <div className='campaign-title'>
                    <h2>Son 1 Adet Fırsatları</h2>
                    <h3><a href='/kampanyali-urunler/all'>Tüm Ürünler <FontAwesomeIcon icon={faChevronRight} style={{ marginLeft: '5px' }} /></a></h3>
                </div>
                <div className="campaign-products-wrapper">
                    <button onClick={scrollLeft} className="scroll-button left">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <div className="campaign-products" ref={productContainerRef}>
                        {campaignProduct.map((product) => (
                            <a
                                key={`${product.id}`}
                                href={`/urun/${product.sef}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="campaign-product-card"
                                style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                            >
                                <div key={product.id} className="campaign-product-card"
                                    onClick={() => handleProductClick(product.sef)}
                                    style={{ cursor: 'pointer' }}>

                                    <img src={getImageUrl(product.image.i1)} alt={product.baslik} onError={(e) => { e.target.onerror = null; }} />

                                    <FontAwesomeIcon
                                        icon={isFavorite(product) ? SolidHeart : RegularHeart}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFavoriteToggle(e, product)
                                        }}
                                        className="favorite-icon"
                                        style={{ color: isFavorite(product) ? 'red' : 'gray', fontSize: window.innerWidth <= 768 ? '16px' : '24px' }}
                                    />
                                    <div className="productt-card-responsive_info">
                                        <h4 className="campaign-responsive-name">{product.baslik}</h4>
                                        <div className="ratings">
                                            {'★'.repeat(5)}
                                        </div>
                                    </div>
                                    <div className="price">
                                        {product.fiyat ? (
                                            <>
                                                <span className="original-price">{product.eski_fiyat.toLocaleString('tr-TR')} TL</span>
                                                <span className="discounted-price">{product.fiyat.toLocaleString('tr-TR')} TL</span>
                                            </>
                                        ) : (
                                            <span className="discounted-price">{product.fiyat.toLocaleString('tr-TR')} TL</span>
                                        )}
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                    <button onClick={scrollRight} className="scroll-button right">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>

            </div>


        </>
    );
};

export default CampaignProducts;