import React, { useContext, useEffect } from 'react';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import { FaHeart, FaShoppingBag } from 'react-icons/fa';
import "./Favorite.css";
import { useImage } from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';

const Favorite = () => {
    const { favorites, removeFavorite, loading, loadFavorites } = useContext(FavoriteContext);
    const { getImageUrl } = useImage();

    useEffect(() => {
        loadFavorites();
    }, []);

    const handleRemoveFavorite = async (product) => {
        try {
            await removeFavorite(product);
        } catch (error) {
            console.error('Favori kaldırma işlemi sırasında hata:', error);
        }
    };

    if (loading) return <Loader />;
    return (

        <div className='favorites'>
            {favorites.length === 0 ? (
                <div className="empty-favorites">
                    <div className="empty-favorites-icon">
                        <FaHeart size={60} />
                    </div>
                    <p>Favori ürün bulunmamaktadır.</p>
                    <p className="empty-favorites-message">Beğendiğiniz ürünleri favorilerinize ekleyerek daha sonra kolayca ulaşabilirsiniz.</p>
                    <a href="/" className="shop-now-button">
                        <FaShoppingBag style={{ marginRight: '8px' }} />
                        Alışverişe Başla
                    </a>
                </div>
            ) : (
                <div className="favori-container">
                    <div className='favorite-title'>
                        <h2><FaHeart style={{ marginRight: '5px', color: 'red', }} />
                            Favorilerim</h2></div>
                    <div className="favori-grid">

                        {favorites.map(product => (
                            <div key={product.sef} className="favori-card">
                                <div className="favori-img-container">
                                    <a href={`/urun/${product.sef}`}>
                                        <img src={getImageUrl(product.image.i1)} alt={product.baslik} onError={(e) => {
                                            e.target.onerror = null;
                                        }} /></a>
                                    <div
                                        onClick={() => handleRemoveFavorite(product)}
                                        className='favorite-icons'

                                    >
                                        <FaHeart color="red" fontSize="16px" />
                                    </div></div>
                                <div className="favori-card-responsive-info">
                                    <h3 className="favori-card-responsive-name">
                                        <a href={`/urun/${product.sef}`}>{product.baslik}</a>
                                    </h3>
                                    <div className="ratingg">
                                        {'★'.repeat(5)}
                                    </div>
                                    <div className="favori-pricee">
                                        {product.fiyat && product.eski_fiyat !== product.fiyat ? (
                                            <>
                                                <h2 className="favori-originall-price" style={{ textDecoration: 'line-through' }}>
                                                    {product.eski_fiyat.toLocaleString('tr-TR')} TL
                                                </h2>
                                                <h2 className="favori-discountedd-price">
                                                    {product.fiyat.toLocaleString('tr-TR')} TL
                                                </h2>
                                            </>
                                        ) : (
                                            <h2 className="favori-originall-price">
                                                {product.fiyat.toLocaleString('tr-TR')} TL
                                            </h2>
                                        )}
                                    </div>

                                    {!product.image.i1 && <p>Ürün fotoğrafı bulunamadı.</p>}
                                </div>

                            </div>

                        ))}
                    </div>
                </div>

            )}
        </div>

    );
};

export default Favorite;