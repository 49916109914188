import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NotFound.css';
import { FaHome } from 'react-icons/fa';

const NotFound = () => {
  useEffect(() => {
    document.title = '404 - Sayfa Bulunamadı | BiKuyum';
    
    return () => {
      document.title = 'BiKuyum';
    };
  }, []);

  return (
    <div className="not-found-container">
      <img 
        src="https://cdn-icons-png.flaticon.com/512/6195/6195678.png" 
        alt="404 İllüstrasyon" 
        className="error-illustration" 
        width="200"
      />
      <h1 className="error-code">404</h1>
      <h2 className="error-title">Sayfa Bulunamadı</h2>
      <p className="error-message">
        Aradığınız sayfa mevcut değil veya taşınmış olabilir. 
        Lütfen URL'yi kontrol edin veya ana sayfaya dönün.
      </p>
      <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Link to="/" className="home-button">
          <FaHome style={{ marginRight: '8px' }} /> Ana Sayfaya Dön
        </Link>
      </div>
    </div>
  );
};

export default NotFound; 