import React, { useState } from 'react';
import './ForgotPassword.css';
import axios from "axios";
import { Link } from "react-router-dom";
import { useToast } from '../../context/ToastContext';



const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const { showToast } = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`https://api.bikuyum.com/api/forgot-password/${email}`)
      .then((res) => {
        showToast('Şifre sıfırlama bağlantısı gönderilmiştir. Lütfen mailinizi kontrol edin!', 'success')
      })
      .catch((error) => {
        setEmailError(true);
        if (error.response) {
          showToast('Sunucu Hatası: ' + (error.response.data.message || 'Bilinmeyen hata'), 'error');
        } else if (error.request) {
          showToast('İstek Hatası: Sunucuya ulaşılamadı', 'error');
        } else {
          showToast('Hata: ' + error.message, 'error');
        }
      });
  };

  return (
    <div className="forgotPassword-container">
      <h1 className="forgotPassword-title">Şifremi Unuttum</h1>
      <p className="forgotPassword-subtitle">Email adresiniz ile şifrenizi sıfırlayarak yeniden giriş yapabilirsiniz</p>

      {showSuccessAlert && (
        <div className="success-alert">
          Şifre sıfırlama bağlantısı email adresinize gönderildi.
        </div>
      )}

      {!showSuccessAlert && (
        <form onSubmit={handleSubmit} className="forgotPassword-form">
          <label className="forgotPassword-label" htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='@example.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`forgotPassword-input ${emailError ? 'input-error' : ''}`}
            required
          />
          <button type="submit" className="forgotPassword-button">Şifremi Sıfırla</button>
        </form>
      )}

      <p className="forgotPassword-loginLink">
        Zaten bir hesabınız var mı? <Link to="/login">Giriş Yap</Link>
      </p>
    </div>
  );
};

export default ForgotPassword;