import React, { createContext, useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';

const initialState = {
  formData: {
    ad: "",
    soyad: "",
    email: "",
    telefon: "",
    sifre: "",
    adres: "",
  },
  loading: true,
  coupons: [],
  referralOrders: [],
  totalCommission: 0,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_USER_DATA':
      return {
        ...state,
        formData: {
          ad: action.payload.ad,
          soyad: action.payload.soyad,
          email: action.payload.email,
          telefon: action.payload.telefon,
          sifre: "",
          adres: action.payload.adres
        },
        loading: false
      };
    case 'UPDATE_USER_DATA':
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case 'SET_COUPONS':
      return { ...state, coupons: action.payload };
    case 'SET_REFERRAL_ORDERS':
      return { 
        ...state, 
        referralOrders: action.payload.orders,
        totalCommission: action.payload.commission 
      };
    default:
      return state;
  }
};



export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch({ type: 'SET_LOADING', payload: false });
        return;
      }
      try {
        const tkn = localStorage.getItem('tkn');
        const response = await fetch("https://api.bikuyum.com/api/user", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`,
            'Tkn': `${tkn}`,
          },
        });
        const userData = await response.json();
        dispatch({ type: 'SET_USER_DATA', payload: userData });
      } catch (error) {
        console.error("Kullanıcı bilgileri yüklenemedi:", error);
      }
    };

    fetchUserData();
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'UPDATE_USER_DATA', payload: { [name]: value } });


  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      // Adres bilgisini localStorage'dan alın
      const savedAddresses = localStorage.getItem('userAddresses');
      let dataToSend = { ...state.formData };

      // Şifre boş bırakılmışsa, API'ye gönderilecek veriden şifreyi çıkar
      if (!dataToSend.sifre || dataToSend.sifre.trim() === '') {
        const { sifre, ...dataWithoutPassword } = dataToSend;
        dataToSend = dataWithoutPassword;
      }

      // Eğer kayıtlı adres varsa, ilk adresi formData'ya ekleyin
      if (savedAddresses) {
        const addresses = JSON.parse(savedAddresses);
        if (addresses.length > 0) {
          dataToSend.adres = addresses[0].addressText;
          dataToSend.il = addresses[0].il;
          dataToSend.ilce = addresses[0].ilce;
        }
      }

      // Eğer hiç adres yoksa ve API adres gerektiriyorsa, kullanıcıya bilgi verin
      if (!dataToSend.adres) {
        alert("Lütfen en az bir adres ekleyin. Adres bilgisi zorunludur.");
        return;
      }

      await axios.put("https://api.bikuyum.com/api/user", dataToSend, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
        },
      });
      alert("Bilgiler başarıyla güncellendi.");
      // Sadece şifre değiştirilmişse logout yap
      if (state.formData.sifre && state.formData.sifre.trim() !== '') {
        logout();
      }
    } catch (error) {
      console.error("Bilgiler güncellenemedi:", error);
      if (error.response && error.response.status === 400) {
        alert("Güncelleme hatası: Adres bilgisi eksik veya hatalı. Lütfen adres ekleyiniz.");
      } else {
        alert("Bilgiler güncellenemedi.");
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('tkn');


    window.location.href = "/login";
  };

  const createCoupon = async (amount) => {
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      const response = await fetch("https://api.bikuyum.com/api/coupons", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
        },
        body: JSON.stringify({ amount })
      });

      const data = await response.json();

      if (data.success) {
        return {
          success: true,
          couponCode: data.couponCode,
          message: "Kupon başarıyla oluşturuldu"
        };
      } else {
        throw new Error(data.message || "Kupon oluşturulamadı");
      }
    } catch (error) {
      console.error("Kupon oluşturma hatası:", error);
      throw error;
    }
  };

  const getCoupons = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      if (!token || !tkn) {
        dispatch({ type: 'SET_COUPONS', payload: [] });
        return [];
      }

      const response = await fetch("https://api.bikuyum.com/api/coupons", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
        }
      });

      if (!response.ok) {
        console.error('API yanıtı başarısız:', response.status);
        dispatch({ type: 'SET_COUPONS', payload: [] });
        return [];
      }

      const data = await response.json();
      dispatch({ type: 'SET_COUPONS', payload: data });
      return data;
    } catch (error) {
      console.error("Kuponlar yüklenirken hata oluştu:", error);
      dispatch({ type: 'SET_COUPONS', payload: [] });
      return [];
    }
  }, [dispatch]);

  const getReferralOrders = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      if (!token || !tkn) {
        dispatch({ type: 'SET_REFERRAL_ORDERS', payload: { orders: [], commission: 0 } });
        return { orders: [], commission: 0 };
      }

      const response = await fetch("https://api.bikuyum.com/api/referral-orders", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
        }
      });

      if (!response.ok) {
        console.error('API yanıtı başarısız:', response.status);
        dispatch({ type: 'SET_REFERRAL_ORDERS', payload: { orders: [], commission: 0 } });
        return { orders: [], commission: 0 };
      }
      const data = await response.json();

      // Veri kontrolü ve güvenli dönüşüm
      if (!data || !data.codes || typeof data.codes !== 'object') {
        dispatch({ type: 'SET_REFERRAL_ORDERS', payload: { orders: [], commission: 0 } });
        return { orders: [], commission: 0 };
      }

      const formattedOrders = Object.entries(data.codes).map(([refCode, refData]) => {
        if (!refData || !refData.products || !Array.isArray(refData.products)) {
          return {
            id: refCode,
            referral_code: refCode,
            products: []
          };
        }

        return {
          id: refCode,
          referral_code: refCode,
          products: refData.products.map(product => ({
            product_image: product?.image || '',
            product_title: product?.title || 'Ürün Adı Yok',
            total_amount: product?.price || 0
          }))
        };
      });

      dispatch({ 
        type: 'SET_REFERRAL_ORDERS', 
        payload: { 
          orders: formattedOrders,
          commission: data.komisyon || 0
        } 
      });
      return { orders: formattedOrders, commission: data.komisyon || 0 };
    } catch (error) {
      console.error("Referans siparişleri yüklenirken hata oluştu:", error);
      dispatch({ type: 'SET_REFERRAL_ORDERS', payload: { orders: [], commission: 0 } });
      return { orders: [], commission: 0 };
    }
  }, [dispatch]);

  // const getAddresses = async () => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const tkn = localStorage.getItem('tkn');
  //     const response = await fetch("https://api.bikuyum.com/api/addresses", {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `${token}`,
  //         'Tkn': `${tkn}`,
  //       },
  //     });
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error("Adresler yüklenemedi:", error);
  //     throw error;
  //   }
  // };

  // const addAddress = async (addressData) => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const tkn = localStorage.getItem('tkn');
  //     const response = await fetch("https://api.bikuyum.com/api/addresses", {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `${token}`,
  //         'Tkn': `${tkn}`,
  //       },
  //       body: JSON.stringify(addressData)
  //     });
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error("Adres eklenemedi:", error);
  //     throw error;
  //   }
  // };

  // const updateAddress = async (addressId, addressData) => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const tkn = localStorage.getItem('tkn');
  //     const response = await fetch(`https://api.bikuyum.com/api/addresses/${addressId}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `${token}`,
  //         'Tkn': `${tkn}`,
  //       },
  //       body: JSON.stringify(addressData)
  //     });
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error("Adres güncellenemedi:", error);
  //     throw error;
  //   }
  // };

  // const deleteAddress = async (addressId) => {
  //   try {
  //     const token = localStorage.getItem('token');
  //     const tkn = localStorage.getItem('tkn');
  //     const response = await fetch(`https://api.bikuyum.com/api/addresses/${addressId}`, {
  //       method: 'DELETE',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': `${token}`,
  //         'Tkn': `${tkn}`,
  //       },
  //     });
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error("Adres silinemedi:", error);
  //     throw error;
  //   }
  // };

  return (
    <UserContext.Provider value={{
      ...state,
      handleInputChange,
      handleSubmit,
      logout,
      // createCoupon,
      // getCoupons,
      getReferralOrders,
      // coupons: state.coupons,
      referralOrders: state.referralOrders,
      totalCommission: state.totalCommission
    }}>
      {children}
    </UserContext.Provider>
  );
};