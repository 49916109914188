import React, { useContext, useEffect } from 'react';
import "./Certificate.css";
import { CertificateContext } from '../../context/CertificateContext';
import { useParams } from 'react-router-dom';



const Certificate = () => {
    const { certificates, certificate, loading, error } = useContext(CertificateContext);
    const { cardNo } = useParams();

    useEffect(() => {
        if (cardNo) {
            certificate(cardNo);
        }
    }, [cardNo]);


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!certificates) return <div>No certificates found</div>;
    return (
        <div className="certificate-container">

            <div className="certificate-card" >
                <img
                    src={certificates.mainImage}

                    className="certificate-image"
                />
                <div className="certificate-info">
                    {certificates ? (
                        <div>
                            {certificates.detail.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </div>
                    ) : (
                        <p>İçerik yükleniyor...</p>
                    )}
                </div>

                {certificates.productImages && certificates.productImages.length > 0 ? (
                    <div className="certificate-product-images">
                        {certificates.productImages.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Ürün Görseli ${index + 1}`}
                                className="certificate-product-image"
                            />
                        ))}
                    </div>
                ) : (
                    <div className="no-images">Ürün görseli bulunamadı</div>
                )}
            </div>
        </div>

    )

}
export default Certificate;
