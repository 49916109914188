import React, { useState, useContext, useEffect, useCallback } from "react";
import { UserContext } from "../../context/UserContext.js";
import { useToast } from "../../context/ToastContext.js";

const CouponComponent = () => {
  const { createCoupon, getCoupons, coupons, getReferralOrders, referralOrders, totalCommission } = useContext(UserContext);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponAmount, setCouponAmount] = useState('');
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      // await getCoupons();
      await getReferralOrders();
    } catch (error) {
      console.error("Veriler yüklenirken hata oluştu:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getCoupons, getReferralOrders]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCouponConversion = async () => {
    try {
      const result = await createCoupon(parseFloat(couponAmount));

      if (result.success) {
        showToast(`${couponAmount}₺ değerinde kupon oluşturuldu. Kupon Kodunuz: ${result.couponCode}`, "success");
        setShowCouponModal(false);
        setCouponAmount('');
        await getCoupons();
      } else {
        showToast("Kupon oluşturulurken bir hata oluştu", "error");
      }
    } catch (error) {
      showToast(error.message || "Kupon oluşturulurken bir hata oluştu", "error");
    }
  };

  return (
    <div className="user-earnings-container">
      <h3 className="user-section-title">Kazançlarım</h3>

      <div className="earnings-summary">
        <div className="earnings-card">
          <h4>Toplam Satış</h4>
          <p className="earnings-value">{referralOrders?.length || 0} Adet</p>
        </div>
        <div className="earnings-card">
          <h4>Toplam Kazanç</h4>
          <p className="earnings-value">
            {referralOrders ? `₺${(referralOrders.reduce((total, order) =>
              total + order.products.reduce((orderTotal, product) =>
                orderTotal + product.total_amount, 0), 0) * 0.01).toFixed(2)}` : '₺0.00'}
          </p>
          {/* <button
            className="convert-to-coupon-btn"
            onClick={() => setShowCouponModal(true)}
          >
            Kupona Dönüştür
          </button> */}
        </div>
      </div>

      {showCouponModal && (
        <div className="user-modal-overlay">
          <div className="user-coupon-modal">
            <h3>Kazancınızı Kupona Dönüştürün</h3>
            <div className="coupon-form">
              <div className="coupon-amount">
                <label>Dönüştürülecek Miktar</label>
                <input
                  type="number"
                  value={couponAmount}
                  onChange={(e) => setCouponAmount(e.target.value)}
                  max={referralOrders ? referralOrders.reduce((total, order) =>
                    total + order.products.reduce((orderTotal, product) =>
                      orderTotal + product.total_amount, 0), 0) * 0.01 : 0}
                  min={100}
                />
              </div>
              <p className="coupon-info">
                * Minimum 100₺ ve üzeri miktarları kupona dönüştürebilirsiniz.
              </p>
              <div className="coupon-actions">
                <button
                  className="cancel-btn"
                  onClick={() => setShowCouponModal(false)}
                >
                  İptal
                </button>
                <button
                  className="convert-btn"
                  onClick={handleCouponConversion}
                  disabled={couponAmount < 100 || couponAmount > (referralOrders ? referralOrders.reduce((total, order) =>
                    total + order.products.reduce((orderTotal, product) =>
                      orderTotal + product.total_amount, 0), 0) * 0.01 : 0)}
                >
                  Kupona Dönüştür
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Kupon Listesi */}
      <div className="coupons-list">
        <h4>Kuponlarım</h4>
        <div className="coupons-container">
          {isLoading ? (
            <p>Kuponlar yükleniyor...</p>
          ) : (
            <>
              <div className="coupons-grid">
                {coupons && coupons.map((coupon) => (
                  <div key={coupon.id} className="coupon-card">
                    <div className="coupon-header">
                      <span className="coupon-code">{coupon.code}</span>
                      <span className={`coupon-status ${coupon.status.toLowerCase()}`}>
                        {coupon.status === 'ACTIVE' ? 'Aktif' :
                          coupon.status === 'USED' ? 'Kullanıldı' : 'İptal Edildi'}
                      </span>
                    </div>
                    <div className="coupon-details">
                      <div className="coupon-amount">
                        <span className="coupon-label">Tutar:</span>
                        <span className="coupon-value">₺{coupon.amount}</span>
                      </div>
                      <div className="coupon-date">
                        <span className="coupon-label">Oluşturma Tarihi:</span>
                        <span className="coupon-value">{new Date(coupon.created_at).toLocaleDateString('tr-TR')}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="coupons-table">
                <table>
                  <thead>
                    <tr>
                      <th>Kupon Kodu</th>
                      <th>Tutar</th>
                      <th>Oluşturma Tarihi</th>
                      <th>Durum</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coupons && coupons.map((coupon) => (
                      <tr key={coupon.id}>
                        <td>{coupon.code}</td>
                        <td>₺{coupon.amount}</td>
                        <td>{new Date(coupon.created_at).toLocaleDateString('tr-TR')}</td>
                        <td>
                          <span className={`coupon-status ${coupon.status.toLowerCase()}`}>
                            {coupon.status === 'ACTIVE' ? 'Aktif' :
                              coupon.status === 'USED' ? 'Kullanıldı' : 'İptal Edildi'}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {(!coupons || coupons.length === 0) && (
                <div className="no-coupons">
                  Henüz oluşturulmuş kupon bulunmamaktadır.
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Referans Kodumla Yapılan Siparişler */}
      <div className="referral-orders-list">
        <h4>Referans Kodumla Yapılan Siparişler</h4>
        <div className="referral-orders-container">
          {isLoading ? (
            <p>Siparişler yükleniyor...</p>
          ) : (
            <>
              <div className="referral-orders-grid">
                {referralOrders && referralOrders.map((order) => (
                  <div key={order.id} className="referral-order-card">
                    <div className="referral-order-header">
                      <span className="referral-code">Referans Kodu: {order.referral_code}</span>
                      <span className="referral-total">
                        Toplam: ₺{order.products.reduce((total, product) => total + product.total_amount, 0).toFixed(2)}
                      </span>
                    </div>
                    <div className="referral-order-products">
                      {order.products.map((product, index) => (
                        <div key={index} className="referral-product-item">
                          {product.product_image && (
                            <img
                              src={`https://panel.bikuyum.com/upload/${product.product_image}`}
                              alt={product.product_title}
                              className="referral-product-image"
                            />
                          )}
                          <div className="referral-product-info">
                            <p className="referral-product-title">{product.product_title}</p>
                            <div className="referral-product-details">
                              <span className="referral-product-price">
                                Tutar: ₺{product.total_amount.toFixed(2)}
                              </span>
                              <span className="referral-product-earning">
                                Kazanç: ₺{(product.total_amount * 0.01).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="referral-orders-table">
                <table>
                  <thead>
                    <tr>
                      <th>Referans Kodu</th>
                      <th>Ürün</th>
                      <th>Tutar</th>
                      <th>Kazanç</th>
                    </tr>
                  </thead>
                  <tbody>
                    {referralOrders && referralOrders.map((order) => (
                      order.products.map((product, index) => (
                        <tr key={`${order.id}-${index}`}>
                          <td><strong>{order.referral_code}</strong></td>
                          <td>
                            <div className="table-image">
                              {product.product_image && (
                                <img
                                  src={`https://panel.bikuyum.com/upload/${product.product_image}`}
                                  alt={product.product_title}
                                  className="referral-product-image"
                                />
                              )}
                              {product.product_title}
                            </div>
                          </td>
                          <td>₺{product.total_amount.toFixed(2)}</td>
                          <td>₺{(product.total_amount * 0.01).toFixed(2)}</td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </table>
              </div>
              {(!referralOrders || referralOrders.length === 0) && (
                <div className="no-referral-orders">
                  Henüz referans kodunuzla yapılmış sipariş bulunmamaktadır.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponComponent; 