import React, { useContext, useState, useEffect } from 'react';
import './Payment.css';
import { PaymentContext } from "../../context/PaymentContext.js";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Loader from '../../components/Loader.js';
import { useToast } from '../../context/ToastContext.js';

Modal.setAppElement('#root');

const Payment = () => {
  const {
    state: { cardNumber, totalAmount, cardHolderName, expiryDate, cvc, installments, commissionRate, selectedInstallment, error, paymentSuccess },
    setCardNumber,
    setCardHolderName,
    setExpiryDate,
    setCvc,
    setTotalAmount,
    fetchCommissionRates,
    fetchInstallments,
    setSelectedInstallment,
    processPayment,
    calculateMonthlyPayment,
    confirmEftPayment
  } = useContext(PaymentContext);
  const location = useLocation();
  const { total, customerForm, cart } = location.state || {};
  const [isOpen, setIsOpen] = useState(false);
  const [isFormActive, setIsFormActive] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedInstallmentData, setSelectedInstallmentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();

  // EFT/Havale ödemesi için state'ler
  const [orderCreated, setOrderCreated] = useState(false);
  const [orderNumber, setOrderNumber] = useState('');
  const [orderAmount, setOrderAmount] = useState(0);
  const [copySuccess, setCopySuccess] = useState('');
  const [eftData, setEftData] = useState(null);
  
  const [invoiceData, setInvoiceData] = useState({
    ad: '',
    soyad: '',
    adres: '',
    dogumTarihi: '',
    tckimlik: '',
    telefon: '05'
  });
  const [invoiceErrors, setInvoiceErrors] = useState({});

  const [paymentState, setPaymentState] = useState({
    success: false,
    orderId: null,
    amount: null
  });

  const isEftPayment = customerForm && customerForm.paymentType === 'eft';

  const handleFocus = () => setIsFormActive(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (total) {
      setTotalAmount(total);
    }
    fetchCommissionRates();
  }, []);

  useEffect(() => {
    if (cardNumber.length == 8 || cardNumber.length == 16) {
      fetchInstallments(cardNumber);
    } else {
      setSelectedInstallment(null);
    }
  }, [cardNumber]);

  // EFT verilerini getir
  useEffect(() => {
    if (isEftPayment && orderCreated) {
      const fetchEftData = async () => {
        try {
          const response = await fetch('https://api.bikuyum.com/api/get_eft');
          const data = await response.json();
          if (data && data.length > 0) {
            setEftData(data[0]);
          }
        } catch (error) {
          showToast('EFT bilgileri şu anda alınamıyor', 'error')
          console.error('EFT bilgileri alınırken hata oluştu:', error);
        }
      };

      fetchEftData();
    }
  }, [isEftPayment, orderCreated]);

  const validateForm = () => {
    const newErrors = {};
    if (!cardNumber || cardNumber.length !== 16) {
      newErrors.cardNumber = 'Geçerli bir kart numarası giriniz';
    }
    if (!cardHolderName || cardHolderName.trim().length < 5) {
      newErrors.cardHolderName = 'Geçerli bir kart sahibi adı giriniz';
    }

    // Son kullanma tarihi validasyonu
    if (!expiryDate || !expiryDate.match(/^(0[1-9]|1[0-2])\/([0-9]{2})$/)) {
      newErrors.expiryDate = 'Geçerli bir son kullanma tarihi giriniz (AA/YY)';
    } else {
      const [month, year] = expiryDate.split('/').map(part => parseInt(part, 10));

      if (month < 1 || month > 12) {
        newErrors.expiryDate = 'Ay değeri 1-12 arasında olmalıdır';
      }

      // Yıl kontrolü
      const currentYear = new Date().getFullYear() % 100;
      if (year < 25) {
        newErrors.expiryDate = 'Kart son kullanma yılı 2025 ve sonrası olmalıdır';
      }

      // Ay-yıl geçerlilik kontrolü
      const currentDate = new Date();
      const expiryDateObj = new Date(2000 + year, month - 1, 1);

      if (expiryDateObj < currentDate) {
        newErrors.expiryDate = 'Kartın son kullanma tarihi geçmiş';
      }
    }

    if (!cvc || !cvc.match(/^[0-9]{3}$/)) {
      newErrors.cvc = 'Geçerli bir CVC giriniz';
    }
    if (!selectedInstallmentData) {
      newErrors.installment = 'Lütfen bir taksit seçiniz';
    }

    return newErrors;
  };

  const handleCardNumberChange = (e) => {
    let newCardNumber = e.target.value.replace(/\D/g, '');
    if (newCardNumber.length > 0) {
      newCardNumber = newCardNumber.slice(0, 16);
    }
    setCardNumber(newCardNumber);
  };

  const handleInstallmentChange = (e) => {
    const selectedValue = Number(e.target.value);
    setSelectedInstallment(selectedValue);
    const selected = installments.find(inst => inst.count === selectedValue);
    setSelectedInstallmentData(selected);
    setErrors(prev => ({ ...prev, installment: '' }));
  };

  const handleCardHolderNameChange = (e) => {
    setCardHolderName(e.target.value);
  };

  const handleExpiryDateChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');

    // Ay kısmı kontrol
    if (input.length > 0) {
      if (input.length === 1 && input > '1') {
        input = '0' + input;
      } else if (input.length >= 2) {
        const month = parseInt(input.substring(0, 2), 10);
        if (month > 12) {
          input = '12' + input.substring(2);
        } else if (month === 0) {
          input = '01' + input.substring(2);
        }
      }
    }

    // Yıl kısmı kontrol
    if (input.length >= 4) {
      const year = parseInt(input.substring(2, 4), 10);
      if (year < 25) {
        input = input.substring(0, 2) + '25';
      }
    }

    // AA/YY formatını oluştur
    if (input.length >= 2) {
      input = input.slice(0, 2) + '/' + input.slice(2, 4);
    }

    setExpiryDate(input);

    if (errors.expiryDate) {
      setErrors(prev => ({ ...prev, expiryDate: '' }));
    }
  };

  useEffect(() => {
    if (total) {
      setTotalAmount(total);
    }
  }, [total]);

  const handleCvcChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 3);
    setCvc(value);
    setErrors(prev => ({ ...prev, cvc: '' }));
  };

  // Fatura formu doğrulama fonksiyonu
  const validateInvoiceForm = () => {
    const newErrors = {};
    if (!invoiceData.ad || invoiceData.ad.trim().length < 2) {
      newErrors.ad = 'Geçerli bir ad giriniz';
    }
    if (!invoiceData.soyad || invoiceData.soyad.trim().length < 2) {
      newErrors.soyad = 'Geçerli bir soyad giriniz';
    }
    if (!invoiceData.adres || invoiceData.adres.trim().length < 5) {
      newErrors.adres = 'Geçerli bir adres giriniz';
    }
    if (!invoiceData.dogumTarihi) {
      newErrors.dogumTarihi = 'Doğum tarihi giriniz';
    }
    if (!invoiceData.telefon || invoiceData.telefon.length < 11 || !/^\d+$/.test(invoiceData.telefon)) {
      newErrors.telefon = 'Geçerli bir telefon giriniz';
    }
    if (!validateTCKN(invoiceData.tckimlik)) {
      newErrors.tckimlik = 'Geçersiz TC Kimlik No';
    }
    return newErrors;
  };

  // TC Kimlik No doğrulama fonksiyonu
  const validateTCKN = (tckn) => {
    if (tckn.length !== 11 || !/^\d+$/.test(tckn)) return false;
    const digits = tckn.split('').map(Number);
    if (digits[0] === 0) return false;

    const oddSum = digits[0] + digits[2] + digits[4] + digits[6] + digits[8];
    const evenSum = digits[1] + digits[3] + digits[5] + digits[7];

    const tenthDigit = (oddSum * 7 - evenSum) % 10;
    if (tenthDigit !== digits[9]) return false;

    const eleventhDigit = digits.slice(0, 10).reduce((a, b) => a + b, 0) % 10;
    return eleventhDigit === digits[10];
  };

  // Fatura bilgisi değişikliği için handler
  const handleInvoiceChange = (e) => {
    const { name, value } = e.target;

    if (name === 'tckimlik') {
      if (value[0] === '0') return;
      if (/^\d*$/.test(value) && value.length <= 11) {
        setInvoiceData(prev => ({ ...prev, [name]: value }));
      }
      return;
    }

    if (name === 'telefon') {
      handlePhoneInput(e);
      return;
    }

    setInvoiceData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      setInvoiceData(prev => ({ ...prev, telefon: '05' }));
    } else if (value.length === 1 && value !== "0") {
      setInvoiceData(prev => ({ ...prev, telefon: '05' }));
    } else {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 11) {
        setInvoiceData(prev => ({ ...prev, telefon: sanitizedValue.substring(0, 11) }));
      } else {
        setInvoiceData(prev => ({ ...prev, telefon: sanitizedValue }));
      }
    }
  };

  const formatPhoneNumber = (number) => {
    return number.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    // Fatura formu doğrulama
    const invoiceFormErrors = validateInvoiceForm();
    if (Object.keys(invoiceFormErrors).length > 0) {
      setInvoiceErrors(invoiceFormErrors);
      return;
    }

    // Kredi kartı için doğrulama yap
    if (!isEftPayment) {
      const formErrors = validateForm();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
      }
    }

    setLoading(true);
    try {
      const { total, customerForm, cart } = location.state || {};
      const referansKodu = location.state?.referansKodu || '';

      // Fatura bilgilerini de ekle
      const updatedCustomerForm = {
        ...customerForm,
        faturaAd: invoiceData.ad,
        faturaSoyad: invoiceData.soyad,
        faturaAdres: invoiceData.adres,
        faturaDogumTarihi: invoiceData.dogumTarihi,
        faturaTckimlik: invoiceData.tckimlik,
        faturaTelefon: invoiceData.telefon,
      };

      // EFT/Havale ödeme yöntemi seçiliyse
      if (customerForm && customerForm.paymentType === 'eft') {
        fbq('track', 'InitiateCheckout');
        try {
          const eftPaymentData = {
            musteriForm: updatedCustomerForm,
            odenecek_tutar: total,
            referans_kodu: referansKodu,
            payment_type: 'eft',
            isEftPayment: true
          };

          const response = await processPayment(eftPaymentData);

          if (response.success) {
            setOrderCreated(true);
            setOrderNumber(response.orderId || 'EFT-' + Date.now());
            setOrderAmount(total);

            // EFT siparişi başarıyla oluşturulduğunda share_ref çerezini sil
            document.cookie = "share_ref=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

            setLoading(false);
          } else {
            throw new Error(response.message || 'EFT/Havale sipariş kaydı başarısız');
          }
        } catch (error) {
          setErrors(prev => ({
            ...prev,
            submit: error.message || 'Sipariş oluşturulurken bir hata oluştu'
          }));
          setLoading(false);
        }
        return;
      }
      // Kredi kartı ödemesi için
      const paymentData = {
        kart_numarasi: cardNumber,
        isim: cardHolderName,
        son_kullanma: expiryDate,
        ccv: cvc,
        taksit_sayisi: selectedInstallmentData.count,
        odenecek_tutar: selectedInstallmentData.totalAmount,
        musteriForm: updatedCustomerForm,
        referans_kodu: referansKodu
      };

      const response = await processPayment(paymentData);

      if (response.success) {
        if (!response.redirect_url) {
          setErrors(prev => ({
            ...prev,
            submit: 'Banka kartlarına taksit yapılamamaktadır.'
          }));
          return;
        }

        // Share_ref çerezini sil
        document.cookie = "share_ref=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

        if (response.redirect_url) {
          window.location.replace(response.redirect_url);
          return;
        } else {
          navigate('/success', {
            state: {
              orderId: response.orderId,
              amount: selectedInstallmentData.totalAmount
            }
          });
        }
      } else {
        // Başarısız ödeme durumunda share_ref çerezini silme
        throw new Error(response.message || 'Ödeme işlemi başarısız');
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        submit: error.message || 'Ödeme işlemi sırasında bir hata oluştu'
      }));
    } finally {
      setLoading(false);
    }
  };

  const defaultInstallments = [
    { count: 1, totalAmount: total },
    { count: 2, totalAmount: total },
    { count: 3, totalAmount: total },
  ];

  // Metin kopyalama fonksiyonu
  const copyToClipboard = (text, label) => {
    try {
      // Yardımcı textarea elementi oluştur
      const textArea = document.createElement('textarea');
      textArea.value = text;
      // Görünmez yap
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);

      // Seç ve kopyala
      textArea.focus();
      textArea.select();

      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);

      if (successful) {
        setCopySuccess(`${label} kopyalandı!`);
        setTimeout(() => setCopySuccess(''), 2000);
      } else {
        // execCommand başarısız olursa modern API'yi dene
        navigator.clipboard.writeText(text)
          .then(() => {
            setCopySuccess(`${label} kopyalandı!`);
            setTimeout(() => setCopySuccess(''), 2000);
          })
          .catch(err => {
            setCopySuccess('Kopyalama başarısız!');
            console.error('Kopyalama hatası:', err);
          });
      }
    } catch (err) {
      setCopySuccess('Kopyalama başarısız!');
      console.error('Kopyalama hatası:', err);
    }
  };

  // EFT ödeme bildirimi fonksiyonu
  const handleConfirmEftPayment = async () => {
    try {
      if (!orderNumber) {
        console.error('Sipariş numarası bulunamadı');

        showToast('Sipariş numarası bulunamadı. Lütfen tekrar deneyin.', 'error');
        return;
      }
      setLoading(true);
      const result = await confirmEftPayment(orderNumber, orderAmount);

      if (result.success) {
        showToast('EFT ödemeniz başarıyla bildirildi.', 'success');

        setPaymentState({
          success: true,
          orderId: orderNumber,
          amount: result.price
        });

      } else {
        showToast(result.message || 'EFT bildirimi yapılırken bir hata oluştu.', 'error');
      }
    } catch (error) {
      console.error('EFT ödeme bildirimi yapılırken hata:', error);
      showToast('EFT bildirimi yapılırken bir hata oluştu.', 'error');
    } finally {
      setLoading(false);
    }
  };

  // EFT ödeme onaylama için useEffect
  useEffect(() => {
    // orderNumber değeri varsa ve orderCreated true ise
    if (orderNumber && orderCreated && isEftPayment) {
      handleConfirmEftPayment();
    }
  }, [orderNumber, orderCreated, isEftPayment]);

  // Fatura adı ve soyadı değiştiğinde kart sahibi adını otomatik güncelle
  useEffect(() => {
    if (invoiceData.ad || invoiceData.soyad) {
      const fullName = `${invoiceData.ad} ${invoiceData.soyad}`.trim();
      if (fullName) {
        setCardHolderName(fullName);
      }
    }
  }, [invoiceData.ad, invoiceData.soyad]);


  if (loading) return <Loader />;
  { error && <p>Hata: {error}</p> }
  { paymentSuccess && <p>Ödeme başarıyla gerçekleşti!</p> }
  return (
    <>
      <div className='payment-title'>
        <h2>Ödeme Sayfası</h2>

        {/* Fatura Bilgileri Formu - EFT ödemesi ve sipariş oluşturulmuşsa gösterme */}
        {!(isEftPayment && orderCreated) && (
          <div>
            <div>
              <div className="payment-information-header">
                <h3>Fatura Bilgileri</h3>
              </div>
              {
                !isEftPayment ?
                  <div>
                    <p className="invoice-info-text"><strong>Fatura bilgileri</strong> kredi kartı sahibi ile <strong>aynı olmalıdır</strong></p>
                    
                    <p className='invoice-info-text'>Son ekrana gelene kadar ürün fiyatları kurlar ile birlikte <strong>güncellenmektedir.</strong> Son ulaştığınız ekrandaki fiyat geçerlidir.</p>
                  </div>
                  :
                  <div className='invoice-group'>
                    <p className="invoice-info-text"><strong>Ücretin gönderildiği hesap</strong> fatura bilgisi ile <strong>uymalıdır</strong>.
                      Aksi takdirde <strong>siparişiniz iptal edilecektir</strong></p>

                    <p className='invoice-info-text'>Son ekrana gelene kadar ürün fiyatları kurlar ile birlikte <strong>güncellenmektedir.</strong> Son ulaştığınız ekrandaki fiyat <strong>10 dakika</strong> için geçerlidir.</p>

                    <p className='invoice-info-text'><strong>10 dakika</strong> içinde ödeme <strong>gerçekleştirilmezse</strong> fiyatlar <strong>anlık</strong> kurla beraber güncellenecektir</p>
                  </div>
              }
            </div>
            <div className="invoice-information">
              <div className="invoice-form">
                <div className="form-group">
                  <label>Ad*</label>
                  <input
                    type="text"
                    name="ad"
                    value={invoiceData.ad}
                    onChange={handleInvoiceChange}
                    placeholder="Adınız"
                    required
                  />
                  {invoiceErrors.ad && <span className="error">{invoiceErrors.ad}</span>}
                </div>

                <div className="form-group">
                  <label>Soyad*</label>
                  <input
                    type="text"
                    name="soyad"
                    value={invoiceData.soyad}
                    onChange={handleInvoiceChange}
                    placeholder="Soyadınız"
                    required
                  />
                  {invoiceErrors.soyad && <span className="error">{invoiceErrors.soyad}</span>}
                </div>

                <div className="form-group">
                  <label>TC Kimlik No*</label>
                  <input
                    type="text"
                    name="tckimlik"
                    value={invoiceData.tckimlik}
                    onChange={handleInvoiceChange}
                    placeholder="TC Kimlik No"
                    maxLength="11"
                    required
                  />
                  {invoiceErrors.tckimlik && <span className="error">{invoiceErrors.tckimlik}</span>}
                </div>

                <div className="form-group">
                  <label>Telefon*</label>
                  <input
                    type="text"
                    name="telefon"
                    value={invoiceData.telefon}
                    onChange={handleInvoiceChange}
                    placeholder="05XX XXX XX XX"
                    maxLength="11"
                    required
                  />
                  {invoiceErrors.telefon && <span className="error">{invoiceErrors.telefon}</span>}
                </div>

                <div className="form-group">
                  <label>Doğum Tarihi*</label>
                  <input
                    type="date"
                    name="dogumTarihi"
                    value={invoiceData.dogumTarihi}
                    onChange={handleInvoiceChange}
                    required
                  />
                  {invoiceErrors.dogumTarihi && <span className="error">{invoiceErrors.dogumTarihi}</span>}
                </div>

                <div className="form-group">
                  <label>Adres*</label>
                  <textarea
                    name="adres"
                    value={invoiceData.adres}
                    onChange={handleInvoiceChange}
                    placeholder="Fatura adresi"
                    required
                  />
                  {invoiceErrors.adres && <span className="error">{invoiceErrors.adres}</span>}
                </div>
              </div>
              {!isEftPayment && (
                <div className={`payment-page ${isFormActive ? "form-active" : ""}`}>
                  <div className="payment-container">
                    <div className="payment-form">
                      <div className="commission-info">
                        <button onClick={() => setIsOpen(true)}>Komisyon Oranları</button>
                        {commissionRate.length > 0 ? (
                          <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} contentLabel="Komisyon Oranları" className="ReactModal__Content"
                            overlayClassName="ReactModal__Overlay">
                            <h2>Komisyon Oranları</h2>
                            <button className="close-button" onClick={() => setIsOpen(false)}>
                              &times;</button>
                            <table>
                              <thead>
                                <tr>
                                  <th>Banka</th>
                                  <th>1 Taksit</th>
                                  <th>2 Taksit</th>
                                  <th>3 Taksit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {commissionRate.map((bank) => {
                                  const taksit1 = bank.taksit.find((t) => t.count === 1)?.rate ?? '';
                                  const taksit2 = bank.taksit.find((t) => t.count === 2)?.rate ?? '';
                                  const taksit3 = bank.taksit.find((t) => t.count === 3)?.rate ?? '';

                                  return (
                                    <tr key={bank.name}>
                                      <td>{bank.name}<img className='bank-img' src={bank.image} alt={bank.name} /></td>
                                      <td>{taksit1}</td>
                                      <td>{taksit2}</td>
                                      <td>{taksit3}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Modal>
                        ) : (
                          <p>Komisyon oranı mevcut değil.</p>
                        )}
                      </div>

                      <form onSubmit={handlePaymentSubmit}>
                        <div className="form-group">
                          <label>Kart Numarası:</label>
                          <input
                            type="text"
                            value={cardNumber}
                            onChange={handleCardNumberChange}
                            maxLength="16"
                            placeholder="Kart numaranızı giriniz"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <label>Kart Üzerindeki Ad Soyad:</label>
                          <input
                            type="text"
                            value={cardHolderName}
                            onChange={handleCardHolderNameChange}
                            placeholder="Kartın üzerindeki ad soyad"
                            required
                            disabled
                          />
                        </div>

                        <div className="form-row form-row-bottom">
                          <div className="small-group">
                            <label>Tarihi:</label>
                            <input
                              type="text"
                              value={expiryDate}
                              onChange={handleExpiryDateChange}
                              placeholder="AA/YY"
                              maxLength="5"
                              required
                            />
                          </div>
                          <div className="small-group">
                            <label>CVC:</label>
                            <input
                              type="text"
                              value={cvc}
                              onChange={handleCvcChange}
                              placeholder="CVC"
                              maxLength="3"
                              required
                            />
                          </div>
                        </div>

                        {cardNumber.length >= 8 && (
                          <div className="installment-options">
                            <h3>Taksit Seçenekleri</h3>
                            <table className="installment-table">
                              <thead>
                                <tr>
                                  <th>Taksit Sayısı</th>
                                  <th>Aylık Ödeme</th>
                                  <th>Banka Komisyonu</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(cardNumber.length < 8 ? defaultInstallments : installments).map((installment) => {
                                  const monthlyPayment = calculateMonthlyPayment(total, installment.count, installment.rate);
                                  const totalPayment = (monthlyPayment * installment.count).toFixed(2);

                                  return (
                                    <tr key={installment.count}>
                                      <td> <label>
                                        <input
                                          type="radio"
                                          name="installment"
                                          value={installment.count}
                                          checked={selectedInstallment === installment.count}
                                          onChange={handleInstallmentChange}
                                        />
                                        {installment.count} Taksit</label>
                                      </td>
                                      <td>
                                        <span>{installment.totalAmount} TL x {installment.count} </span>
                                      </td>
                                      <td>
                                        <span>{installment.rate} %</span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            {errors.submit && (
                              <p className="payment-error-message">{errors.submit}</p>
                            )}
                          </div>
                        )}

                        <button type="submit" disabled={loading} className='payment-button'>
                          {loading ? 'İşlem Yapılıyor...' : 'Ödeme Yap'}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Ödeme Bilgileri Sekmesi */}
        <div className="payment-information-section">
          <div className="">
            {/* EFT veya Kart Bilgileri */}
            {isEftPayment && (
              <div>
                {!orderCreated ? (
                  // Sipariş oluşturulmadan önce
                  <div className='eft-confirm-container'>
                    <p>Siparişinizi oluşturmak için aşağıdaki butona tıklayın. Sonrasında ödeme bilgileriniz görüntülenecektir.</p>
                    <button
                      onClick={handlePaymentSubmit}
                      className="eft-confirm-button"
                      disabled={loading}
                    >
                      {loading ? 'İşlem Yapılıyor...' : 'Siparişi Oluştur'}
                    </button>
                    {errors.submit && (
                      <div className="eft-error-message">
                        <p>{errors.submit}</p>
                      </div>
                    )}
                  </div>
                ) : (
                  // Sipariş oluşturulduktan sonra
                  <div>
                    <h3>EFT/Havale ile Ödeme - Sipariş Onayınız</h3>
                    <p>Siparişiniz başarıyla oluşturuldu. Aşağıdaki banka hesabına ödemenizi yapınız. Ödeme sonrası siparişiniz onaylanarak işleme alınacaktır.</p>

                    <div className="order-details">
                      <p>
                        <strong>Sipariş Numarası:</strong> {orderNumber}
                      </p>
                      <p><strong>Ödenecek Tutar:</strong> {paymentState.success ? paymentState.amount : orderAmount.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</p>
                      <p className='order-details-info'>
                        10 Dakika içerisinde ödeme yapmazsınız fiyat güncellenecektir.
                      </p>
                    </div>

                    <div className="bank-account-info">
                      <div>
                        <p><strong>Banka:</strong></p>
                        <p>{eftData ? eftData.bank : 'Yükleniyor...'}</p>
                      </div>
                      <div>
                        <p><strong>IBAN:</strong></p>
                        <p>
                          {eftData ? eftData.iban : 'Yükleniyor...'}
                          <button
                            className="copy-button"
                            onClick={() => copyToClipboard(eftData ? eftData.iban : '', 'IBAN')}
                          >
                            <span>📋</span>
                          </button>
                        </p>
                      </div>
                      <div>
                        <p><strong>Hesap Sahibi:</strong></p>
                        <p>
                          {eftData ? eftData.name : 'Yükleniyor...'}
                          <button
                            className="copy-button"
                            onClick={() => copyToClipboard(eftData ? eftData.name : '', 'Hesap sahibi')}
                          >
                            <span>📋</span>
                          </button>
                        </p>
                      </div>
                      <div>
                        <p><strong>Açıklama:</strong></p>
                        <p>
                          {orderNumber} Nolu Sipariş
                          <button
                            className="copy-button"
                            onClick={() => copyToClipboard(`${orderNumber} Nolu Sipariş`, 'Açıklama')}
                          >
                            <span>📋</span>
                          </button>
                        </p>
                      </div>
                    </div>

                    {copySuccess && <div className="copy-success-message">{copySuccess}</div>}

                    <div className="eft-important-notice">
                      <p><strong>ÖNEMLİ:</strong> Ödeme yaparken açıklama kısmına mutlaka sipariş numaranızı belirtiniz. Sipariş numarasız ödemeler eşleştirilemeyebilir.</p>
                      <p>Ödemeniz kontrol edildikten sonra siparişiniz onaylanacaktır.</p>
                      <p>Sipariş sahibi: <strong>{customerForm?.ad} {customerForm?.soyad}</strong></p>
                    </div>
                  </div>

                )}

              </div>
            )}


          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;