import React, { useState, useContext, useEffect } from "react";
import "./User.css";
import OrderDetails from "../card/OrderDetails.js";
import { UserContext } from "../../context/UserContext.js";
import { FaUser } from "react-icons/fa";
import AddressComponent from "../../components/user/AddressComponent.js";
import CouponComponent from "../../components/user/CouponComponent.js";

const User = () => {
  const { formData, handleInputChange, handleSubmit, loading } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState('settings');
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    const savedProfileImage = localStorage.getItem('profileImage');
    if (savedProfileImage) {
      setPreviewImage(savedProfileImage);
    }
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  if (loading) {
    return <div className="user-loading-container"><p>Yükleniyor...</p></div>;
  }

  return (
    <div className="user-profile-container">
      <div className="user-profile-header">
        <div className="user-profile-info">
          <div className="user-profile-image-container">
            {previewImage ? (
              <img src={previewImage} alt="Profil" className="user-profile-image" />
            ) : (
              <div className="user-profile-image-placeholder">
                <FaUser size={50} />
              </div>
            )}
          </div>
          <div className="user-profile-text">
            <h2>Merhaba, {formData.ad} {formData.soyad}</h2>
            <p>Hesap bilgilerinizi ve sipariş geçmişinizi bu sayfadan yönetebilirsiniz</p>
          </div>
        </div>
      </div>

      <div className="user-profile-content">
        <div className="user-settings-navigation">
          <button
            className={activeTab === 'settings' ? 'user-active-tab' : ''}
            onClick={() => handleTabClick('settings')}
          >
            Hesap Bilgilerim
          </button>
          <button
            className={activeTab === 'my-earnings' ? 'user-active-tab' : ''}
            onClick={() => handleTabClick('my-earnings')}
          >
            Kazançlarım
          </button>
          {/* <button
            className={activeTab === 'addresses' ? 'user-active-tab' : ''}
            onClick={() => handleTabClick('addresses')}
          >
            Adreslerim
          </button> */}
          <button
            className={activeTab === 'orders' ? 'user-active-tab' : ''}
            onClick={() => handleTabClick('orders')}
          >
            Siparişlerim
          </button>
        </div>

        {activeTab === 'settings' && (
          <div className="user-settings-container">
            <h3 className="user-section-title">Hesap Bilgilerim</h3>
            <form onSubmit={handleSubmit} className="user-account-form">
              <div className="user-form-row">
                <div className="user-form-user">
                  <label>Ad</label>
                  <input
                    type="text"
                    name="ad"
                    value={formData.ad}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="user-form-user">
                  <label>Soyad</label>
                  <input
                    type="text"
                    name="soyad"
                    value={formData.soyad}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="user-form-row">
                <div className="user-form-user">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="user-form-user">
                  <label>Telefon</label>
                  <input
                    type="number"
                    name="telefon"
                    value={formData.telefon}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>

              <div className="user-form-user">
                <label>Şifreniz</label>
                <input
                  type="password"
                  name="sifre"
                  value={formData.sifre}
                  onChange={handleInputChange}
                  placeholder="Şifrenizi değiştirmek için yeni şifrenizi giriniz"
                />
              </div>

              <button type="submit" className="user-update-button">
                Bilgilerimi Güncelle
              </button>
            </form>
          </div>
        )}

        {activeTab === 'my-earnings' && <CouponComponent />}

        {activeTab === 'addresses' && <AddressComponent />}

        {activeTab === "orders" && (
          <div className="user-orders-container">
            <h3 className="user-section-title">Siparişlerim</h3>
            <OrderDetails />
          </div>
        )}
      </div>
    </div>
  );
}

export default User;