import React, { useEffect, useState, useRef } from "react";
import "./Home.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useImage } from "../../context/ImageContext.js";
import ProductList from "../product/ProductList.js";
import CampaignProducts from "../product/CampaignProducts.js";
import { Helmet } from "react-helmet-async";
import Loader from '../../components/Loader.js';

const Home = () => {
  const { getImageUrl } = useImage();
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const sliderRef = useRef(null);


  useEffect(() => {

    const fetchLogos = async () => {
      try {
        const token = localStorage.getItem('token');
        const tkn = localStorage.getItem('tkn');
        const response = await axios.get("https://api.bikuyum.com/api/stores", {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Tkn': `${tkn}`,
            'Content-Type': 'application/json',

          },

        });
        setStores(response.data);

      } catch (error) {
        console.error("API'den veriler çekilirken hata oluştu:", error);
      }
    };

    fetchLogos();
  }, []);


  useEffect(() => {
    if (stores.length > 0) {
      stores.forEach((store) => {
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = store.image;
        document.head.appendChild(link);
      });
    }
  }, [stores]);


  const sliderSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1200,
    pauseOnHover: true,
    draggable: true,
    swipeToSlide: true,
    grabCursor: true,
    touchRatio: 1,
    resistance: false,

    responsive: [
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 3,
          spaceBetween: 10,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          spaceBetween: 15,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          spaceBetween: 15,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          spaceBetween: 20,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 10,
          spaceBetween: 20,
        },
      },
    ],
    watchSlidesProgress: true,
    preventInteractionOnTransition: false
  };
  useEffect(() => {
    const sliderElement = sliderRef.current;

    if (sliderElement) {

      sliderElement.addEventListener("mousedown", (e) => {
        sliderElement.style.cursor = "grabbing";
        e.preventDefault();

        const startX = e.pageX - sliderElement.offsetLeft;
        const scrollLeft = sliderElement.scrollLeft;

        const onMouseMove = (moveEvent) => {
          const x = moveEvent.pageX - sliderElement.offsetLeft;
          const scroll = scrollLeft - (x - startX);
          sliderElement.scrollLeft = scroll;
        };

        const onMouseUp = () => {
          sliderElement.removeEventListener("mousemove", onMouseMove);
          sliderElement.removeEventListener("mouseup", onMouseUp);
          sliderElement.style.cursor = "grab";
        };

        sliderElement.addEventListener("mousemove", onMouseMove);
        sliderElement.addEventListener("mouseup", onMouseUp);
      });
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("mousedown", (e) => { });
      }
    };
  }, []);

  if (loading) return <Loader />;
  return (
    <>
      <div>
        <Helmet>
          <title>BiKuyum| İkinci El Altınlar</title>
        </Helmet>

      </div>
      <div className="slider-container">
        {stores.length > 0 && (
          <Slider {...sliderSettings}>
            {stores.map((store) => (

              <a
                href={`/${store.magaza_sef}/all`}
                key={store.kullanici_id}
                className="store-logo-link"
              >
                <div className="store-logo-item">
                  <img
                    src={getImageUrl(store.image)}
                    alt="magaza_adi"
                    className="store-logo"
                  />
                </div>
                <div>
                  <p className="storep">{store.magaza_adi}</p>
                </div>
              </a>
            ))}
          </Slider>
        )}
      </div>

      <div>
        <CampaignProducts />
      </div>
      <div>
        <ProductList />
      </div>

    </>
  );

}
export default Home;

