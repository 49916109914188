import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { ProductProvider } from './context/ProductContext.js';
import { StoreProvider } from './context/StoreContext.js';
import { CardProvider } from './context/CardContext.js';
import { CategoryProvider } from './context/CategoryContext.js';
import { FavoriteProvider } from './context/FavoriteContext.js';
import { OrderProvider } from './context/OrderContext.js';
import { UserProvider } from './context/UserContext.js';
import { PaymentProvider } from './context/PaymentContext.js';
import { InstitutionalProvider } from './context/InstitutionalContext.js';
import { ImageProvider } from './context/ImageContext.js';
import { HelmetProvider } from 'react-helmet-async';
import { CertificateProvider } from './context/CertificateContext.js';
import { ToastProvider } from './context/ToastContext.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ToastProvider>
    <HelmetProvider>
      <ImageProvider>
        <CategoryProvider>
          <StoreProvider>
            <OrderProvider >
              <ProductProvider>
                <FavoriteProvider>
                  <CardProvider>
                    <UserProvider>
                      <PaymentProvider>
                        <InstitutionalProvider>
                          <CertificateProvider>
                              <App />
                          </CertificateProvider>
                        </InstitutionalProvider>
                      </PaymentProvider>
                    </UserProvider>
                  </CardProvider>
                </FavoriteProvider>
              </ProductProvider>
            </OrderProvider>
          </StoreProvider>
        </CategoryProvider>
      </ImageProvider>
    </HelmetProvider>
  </ToastProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
