import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import React from "react";
import { PaymentContext } from "../../context/PaymentContext";


const PaymentResult = () => {
  const { paymentResult } = useContext(PaymentContext);
  const { status } = useParams();
  const { allParams } = useParams();

  useEffect(() => {
    paymentResult(allParams);
  }, [allParams]);

  useEffect(() => {
    const handlePaymentResult = async () => {
      if (allParams) {
        const result = await paymentResult(allParams);
        
        if (status === 'success' && result?.amount) {
          // Ödeme başarılı olduğunda share_ref çerezini sil
          document.cookie = "share_ref=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          
          fbq('track', 'Purchase', {
            value: parseFloat(result.amount),
            currency: 'TRY',
            content_ids: result.order_id || '',
            content_type: 'product'
          });
        }
      }
    };
    
    handlePaymentResult();
  }, [allParams, status, paymentResult]);

  return (

    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      {status === 'success' && (
        <div style={{ color: 'green' }}>
          <h1>Ödeme Başarılı</h1>
          <p>Ödemeniz başarıyla tamamlandı. Teşekkür ederiz</p>
          <a href="/">Ana Sayfaya Dön</a>
        </div>
      )}

      {status === 'failure' && (
        <div style={{ color: 'red' }}>
          <h1>Ödeme Başarısız</h1>
          <p>Ödeme işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.</p>
          <a href="/">Ana Sayfaya Dön</a>
        </div>
      )}
    </div>

  );

}
export default PaymentResult;