import React, { useState, useEffect } from 'react';

const WhatsAppButton = () => {
  const [iconSize, setIconSize] = useState(60);
  
  useEffect(() => {
    // Ekran boyutunu kontrol eden fonksiyon
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIconSize(40); // Mobil cihazlar için küçük boyut
      } else {
        setIconSize(60); // Masaüstü için normal boyut
      }
    };
    
    // Sayfa yüklendiğinde ve boyut değiştiğinde çalıştır
    handleResize();
    window.addEventListener('resize', handleResize);
    
    // Temizleme fonksiyonu
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const whatsappStyle = {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    padding: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  };

  // Otomatik mesajı URL için kodlama
  const message = encodeURIComponent("Merhaba! Bilgi almak istiyorum.");

  return (
    <a 
      href={`https://wa.me/905441000229?text=${message}`}
      target="_blank"
      rel="noopener noreferrer"
      style={whatsappStyle}
    >
      <img 
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        width={iconSize}
        height={iconSize}
      />
    </a>
  );
};

export default WhatsAppButton;