import React, { createContext, useReducer, useContext } from 'react';

import axios from 'axios';

const initialState = {
  cardHolderName: '',
  cardNumber: '',
  expiryDate: '',
  cvc: '',
  totalAmount: '',
  installments: [],
  commissionRate: [],
  selectedInstallment: '',
  paymentSuccess: false,
  loading: false,
  error: null,

};

const paymentReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CARD_NUMBER':
      return { ...state, cardNumber: action.payload };
    case 'SET_CARD_HOLDER_NAME':
      return { ...state, cardHolderName: action.payload };

    case 'SET_EXPIRY_DATE':
      return { ...state, expiryDate: action.payload };

    case 'SET_CVC':
      return { ...state, cvc: action.payload };

    case 'FETCH_INSTALLMENTS_REQUEST':
      return { ...state, loading: true };

    case 'FETCH_INSTALLMENTS_SUCCESS':
      return { ...state, loading: false, installments: action.payload };

    case 'FETCH_INSTALLMENTS_ERROR':
      return { ...state, loading: false, error: action.payload };

    case 'FETCH_COMMISSION_REQUEST':
      return { ...state, loading: true };

    case 'FETCH_COMMISSION_SUCCESS':
      return { ...state, loading: false, commissionRate: action.payload };

    case 'FETCH_COMMISSION_ERROR':
      return { ...state, loading: false, error: action.payload };

    case 'SET_SELECTED_INSTALLMENT':
      return { ...state, selectedInstallment: action.payload };
    case 'SET_TOTAL_AMOUNT':
      return { ...state, totalAmount: action.payload };
    case 'PAYMENT_REQUEST':
      return { ...state, loading: true, paymentSuccess: false, error: null };

    case 'PAYMENT_SUCCESS':
      return { ...state, loading: false, paymentSuccess: true };

    case 'PAYMENT_ERROR':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paymentReducer, initialState);


  const setCardNumber = (number) => {
    dispatch({ type: 'SET_CARD_NUMBER', payload: number });
  };
  const setCardHolderName = (name) => {
    dispatch({ type: 'SET_CARD_HOLDER_NAME', payload: name });
  };

  const setExpiryDate = (month) => {
    dispatch({ type: 'SET_EXPIRY_DATE', payload: month });
  };

  const setCvc = (cvc) => {
    dispatch({ type: 'SET_CVC', payload: cvc });
  };

  const setTotalAmount = (total) => {
    dispatch({ type: 'SET_TOTAL_AMOUNT', payload: total });
  };


  const calculateMonthlyPayment = (totalAmount, installmentCount, installmentRate) => {
    if (installmentCount > 0) {
      return ((totalAmount * (1 + installmentRate / 100)) / installmentCount).toFixed(2);
    }
    return 0;
  };
  const fetchCommissionRates = async () => {
    dispatch({ type: 'FETCH_COMMISSION_REQUEST' });
    try {
      const tkn = localStorage.getItem('tkn');
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.bikuyum.com/api/bank_list', {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',

        },

      });
      const data = await response.json();

      if (Array.isArray(data) && data.length > 0) {
        const formattedData = data.map(bank => {
          return {
            name: bank.name,
            image: bank.image,
            taksit: Object.entries(bank.taksit).map(([count, rate]) => ({
              count: parseInt(count),
              rate: rate,

            }))
          };
        });
        dispatch({ type: 'FETCH_COMMISSION_SUCCESS', payload: formattedData });
      } else {
        throw new Error('Geçersiz veri formatı');
      }
    } catch (error) {
      console.error('API çağrısı sırasında hata oluştu:', error);
      dispatch({ type: 'FETCH_COMMISSION_ERROR', payload: error.message });
    }
  };

  const fetchInstallments = async (card_number) => {

    const cardDetails = {
      card_number: card_number,
    };

    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch('https://api.bikuyum.com/api/card_info', {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cardDetails),
      });


      const data = await response.json();
      fbq('track', 'AddPaymentInfo');
      const formattedInstallments = Object.entries(data.installment_rates).map(
        ([count, rate]) => ({
          count: parseInt(count),
          rate: rate,
          totalAmount: calculateMonthlyPayment(state.totalAmount, parseInt(count), rate),
        })
      );

      dispatch({ type: 'FETCH_INSTALLMENTS_SUCCESS', payload: formattedInstallments });
    } catch (error) {
      dispatch({ type: 'FETCH_INSTALLMENTS_ERROR', payload: error.message });

    }
  };

  const setSelectedInstallment = (installment) => {
    dispatch({ type: 'SET_SELECTED_INSTALLMENT', payload: installment });
  };
  const paymentResult = async (allParams) => {
    try {

      const response = await fetch('https://api.bikuyum.com/api/payment/success', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(allParams),
      });

      if (!response.ok) {
        throw new Error('Veri gönderme işlemi başarısız oldu.');
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Veri gönderilirken bir hata oluştu:', error);
    }
  };

  const processPayment = async (paymentData) => {
    dispatch({ type: 'PAYMENT_REQUEST' });
    const token = localStorage.getItem('token');
    const tkn = localStorage.getItem('tkn');
    try {
      const response = await axios.post('https://api.bikuyum.com/api/send_order2', paymentData, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.success) {
        dispatch({ type: 'PAYMENT_SUCCESS' });
        fbq('track', 'InitiateCheckout');
        return {
          success: true,
          redirect_url: response.data.redirect_url,
          orderId: response.data.order_id
        };
      } else {
        dispatch({ type: 'PAYMENT_ERROR', payload: response.data.message });
        return {
          success: false,
          message: response.data.message || 'Ödeme işlemi sırasında hata oluştu'
        };
      }
    } catch (error) {
      console.error("Payment Error:", error);
      dispatch({ type: 'PAYMENT_ERROR', payload: error.message });
      return {
        success: false,
        message: error.message || 'Ödeme işlemi sırasında bir hata oluştu'
      };
    }
  };

  // EFT ödeme bildirimi için fonksiyon
  const confirmEftPayment = async (orderNumber, orderAmount) => {
    dispatch({ type: 'PAYMENT_REQUEST' });
    try {
      if (!orderNumber) {
        throw new Error('Sipariş numarası (Order ID) gereklidir');
      }

      const orderNumberStr = String(orderNumber);
      const orderId = orderNumberStr.startsWith('EFT-') ? orderNumberStr.substring(4) : orderNumberStr;

      const data = {
        order: orderId,
        tutar: orderAmount
      };

      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');

      const response = await axios.post('https://api.bikuyum.com/api/payment/success_eft', data, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        }
      });

      console.log(response);
      
      
      if (response.data.success) {
        dispatch({ type: 'PAYMENT_SUCCESS' });
        // fbq('track', 'InitiateCheckout');
        fbq('track', 'Purchase', {
          currency: 'TRY',
          value: response.data.price
        });
        return {
          success: true,
          price: response.data.price,
          message: 'EFT ödemeniz başarıyla bildirildi.'
        };
      } else {
        dispatch({ type: 'PAYMENT_ERROR', payload: response.data.message });
        return {
          success: false,
          message: response.data.message || 'EFT bildirimi yapılırken bir hata oluştu'
        };
      }
    } catch (error) {
      console.error("EFT Payment Error:", error);
      dispatch({ type: 'PAYMENT_ERROR', payload: error.message });
      return {
        success: false,
        message: error.message || 'EFT bildirimi yapılırken bir hata oluştu'
      };
    }
  };


  return (
    <PaymentContext.Provider
      value={{
        state,
        setCardNumber,
        setCardHolderName,
        setExpiryDate,
        setCvc,
        setTotalAmount,
        fetchCommissionRates,
        fetchInstallments,
        setSelectedInstallment,
        processPayment,
        paymentResult,
        calculateMonthlyPayment,
        confirmEftPayment
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

