import React, { createContext, useReducer, useEffect } from 'react';

const initialState = {
  favorites: [],
}

const favoriteReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_FAVORITES':
      return { 
        ...state, 
        favorites: [...state.favorites, action.payload],
        loading: false 
      };
    case 'REMOVE_FROM_FAVORITES':
      const updatedFavorites = state.favorites.filter(product => 
        String(product.favorite_id) !== String(action.payload.favorite_id)
      );
      return { 
        ...state, 
        favorites: updatedFavorites,
        loading: false 
      };
    case 'LOAD_FAVORITES':
      return { 
        ...state, 
        favorites: action.payload,
        loading: false 
      };
    case 'SET_LOADING':
      return { ...state, loading: true };
    default:
      return state;
  }
};
export const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(favoriteReducer, {
    ...initialState,
    loading: true
  });

  const addToFavorite = async (product) => {
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch(`https://api.bikuyum.com/api/favorites`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          urun_id: product.id
        })
      });
      const data = await response.json();
      if (data.message) {
        await loadFavorites();
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
    fbq('track', 'AddToWishlist');
  };

  const removeFavorite = async (product) => {
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch(`https://api.bikuyum.com/api/favorites?id=${product.favorite_id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();

      if (data.message === "Favoriler güncellendi") {
        dispatch({ type: 'REMOVE_FROM_FAVORITES', payload: product });
        await loadFavorites();
      }

    } catch (error) {
      console.error('Error removing product from favorites:', error);
      await loadFavorites();
    }
  };

  const loadFavorites = async () => {
    dispatch({ type: 'SET_LOADING' });
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch('https://api.bikuyum.com/api/favorites', {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      const correctedData = data.map(favorite => {
        if (typeof favorite.image === 'string') {
            try {
                const imageUrls = JSON.parse(favorite.image.replace(/\\/g, ''));
                return { ...favorite, image: imageUrls };
            } catch (error) {
                console.error('Image JSON parse edilemedi:', error);
                return favorite;
            }
        }
        return favorite;
    });

      dispatch({ type: 'LOAD_FAVORITES', payload: correctedData });
    } catch (error) {
      console.error('Error loading favorites:', error);
      dispatch({ type: 'LOAD_FAVORITES', payload: [] });
    }
  };

  useEffect(() => {
    loadFavorites();
  }, []);
  return (
    <FavoriteContext.Provider value={{ 
      favorites: state.favorites, 
      loading: state.loading,
      addToFavorite, 
      removeFavorite,
      loadFavorites 
  }}>
      {children}
  </FavoriteContext.Provider>
  );
};

