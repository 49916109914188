import React, { useState, useRef, useEffect } from 'react';
import '../product/ImageGallery.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";


const ImageGallery = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isZoomed, setIsZoomed] = useState(false); 
    const [zoomStyle, setZoomStyle] = useState({}); 
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [showButtons, setShowButtons] = useState(false);
    const mainImageRef = useRef(null);
    const thumbnailContainerRef = useRef(null);
    const thumbnailRefs = useRef([]);
    
    const baseUrl = 'https://panel.bikuyum.com/upload/';

    const fullImageUrl = images[currentIndex].startsWith('https') 
        ? images[currentIndex] 
        : `${baseUrl}${images[currentIndex]}?size=full`;
        
    useEffect(() => {
        // Resim boyutlarını ayarla
        const img = new Image();
        img.src = fullImageUrl;
        img.onload = () => {
            setImageDimensions({
                width: img.width,
                height: img.height
            });
        };
    }, [currentIndex, fullImageUrl]);
    
    useEffect(() => {
        // Aktif thumbnail'a scroll yap
        if (thumbnailContainerRef.current && thumbnailRefs.current[currentIndex]) {
            const container = thumbnailContainerRef.current;
            const thumbnail = thumbnailRefs.current[currentIndex];
            
            const scrollLeft = thumbnail.offsetLeft - container.offsetWidth / 2 + thumbnail.offsetWidth / 2;
            
            container.scrollTo({
                left: scrollLeft,
                behavior: 'smooth'
            });
        }
        
        // Thumbnail referanslarını temizle ve yeniden oluştur
        thumbnailRefs.current = thumbnailRefs.current.slice(0, images.length);
    }, [currentIndex, images.length]);
    
    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };
  
    const handleMouseMove = (e) => {
        if (!mainImageRef.current || imageDimensions.width === 0 || imageDimensions.height === 0) return;

        const { left, top, width, height } = mainImageRef.current.getBoundingClientRect();
        const offsetX = e.clientX - left;
        const offsetY = e.clientY - top;
        const zoomAreaSize = 400;
        const halfSize = zoomAreaSize /2;
        const zoomFactorX = imageDimensions.width / width * 0.6;
        const zoomFactorY = imageDimensions.height / height * 0.6;
        const maxZoom = 0.6; 
       
      
        setZoomStyle({
            
            backgroundImage: `url(${fullImageUrl})`,
            backgroundSize: `${imageDimensions.width * maxZoom}px ${imageDimensions.height * maxZoom}px`,
            backgroundPosition: `-${offsetX * zoomFactorX - halfSize}px -${offsetY * zoomFactorY - halfSize}px`,
            width: `${zoomAreaSize}px`, 
            height: `${zoomAreaSize}px`, 
            position: 'absolute', 
            left: '100%', 
            transform: 'translate(10px, 0)', 
            border: '1px solid #ccc', 
            zIndex: 10, 
            marginTop:'40px',
            
        });
    };
    
    return (
        <div 
            className="image-gallery"
            onMouseEnter={() => setShowButtons(true)}
            onMouseLeave={() => setShowButtons(false)}
        >
            <div 
                className="main-image" 
                ref={mainImageRef}
                onMouseEnter={() => setIsZoomed(true)} 
                onMouseLeave={() => setIsZoomed(false)} 
                onMouseMove={handleMouseMove} 
            >
                <img src={fullImageUrl} alt={`Product image ${currentIndex + 1}`} />
            </div>
            {isZoomed && (
                <div className="zoomed-image" style={zoomStyle} />
            )}
            <button 
                className={`carousel-button prev ${showButtons ? 'visible' : ''}`} 
                onClick={handlePrev}
            >
                <IoIosArrowBack />
            </button>
            <button 
                className={`carousel-button next ${showButtons ? 'visible' : ''}`} 
                onClick={handleNext}
            >
                <IoIosArrowForward />
            </button>
            <div className="thumbnail-container" ref={thumbnailContainerRef}>
            {images.length > 0 && images.map((image, index) => {
                    const imageUrl = image.startsWith('https') 
                        ? image 
                        : `${baseUrl}${image}`;

                    return (
                        <img 
                            key={index}
                            ref={el => thumbnailRefs.current[index] = el}
                            src={imageUrl} 
                            alt={`Thumbnail ${index + 1}`} 
                            className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
                            onClick={() => setCurrentIndex(index)} 
                        />
                    );
                })}
            </div>
            
        </div>
    );
};

export default ImageGallery;