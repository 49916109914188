import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams, useLocation, Navigate } from "react-router-dom";
import { ProductContext } from '../../context/ProductContext.js';
import Sidebar from '../../components/Sidebar.js';
import "./CategoriesProduct.css";
import { FaSearch } from 'react-icons/fa';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as SolidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as RegularHeart } from '@fortawesome/free-regular-svg-icons';
import { FavoriteContext } from '../../context/FavoriteContext.js';
import { useImage } from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';
import NotFound from '../notFound/NotFound.js';

const CategoriesProduct = () => {
    const { getImageUrl } = useImage();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const sort = searchParams.get('sort') || "newest";
    const { state, fetchCategoryProducts, dispatch, handleMaxPriceChange, handleMinPriceChange, filterByPrice, } = useContext(ProductContext);
    const { products, loading, error, filteredProducts,
        minPrice, maxPrice, sortOrder } = state;
    const [hasMore, setHasMore] = useState(true);
    const { favorites, addToFavorite, removeFavorite, loadFavorites } = useContext(FavoriteContext);
    const navigate = useNavigate();
    const [shouldRedirectTo404, setShouldRedirectTo404] = useState(false);


    const isFavorite = (product) => {
        return favorites.some(favProduct =>
            String(favProduct.urun_id) === String(product.id)
        );
    };

    const handleFavoriteToggle = async (event, product) => {
        event.stopPropagation();
        event.preventDefault();

        try {
            if (isFavorite(product)) {
                const favoriteProduct = favorites.find(fav =>
                    String(fav.urun_id) === String(product.id)
                );
                if (favoriteProduct) {
                    await removeFavorite(favoriteProduct);
                }
            } else {
                await addToFavorite(product);
            }
        } catch (error) {
            console.error('Favori işlemi sırasında hata:', error);
        }

        // Olayın daha fazla yayılmasını engelle
        if (event.nativeEvent) {
            event.nativeEvent.stopImmediatePropagation();
        }

        return false;
    };
    useEffect(() => {
        loadFavorites();
    }, []);

    useEffect(() => {

        if (id) {
            fetchCategoryProducts(id, state.currentPage, sort)
                .catch(err => {
                    setShouldRedirectTo404(true);
                });
        }
    }, []);



    const handleScroll = useCallback(_.throttle(() => {
        const scrollThreshold = window.innerWidth < 768 ? 1400 : 700;
        if (loading || !hasMore) return;

        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - scrollThreshold) {
            fetchCategoryProducts(id, state.currentPage, sort);
        }
    }, 500), [loading, hasMore, id, fetchCategoryProducts, state.currentPage, sort]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);


    const handleSortChangeWithRedirect = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue === "price_asc") {
            navigate(`?sort=price_asc`);
            window.location.reload();
        } else if (selectedValue === "price_desc") {
            navigate(`?sort=price_desc`);
            window.location.reload();
        } else {
            navigate(`?sort=newest`);
            window.location.reload();
        }

    };

    // Eğer hata varsa veya 404'e yönlendirilmesi gerekiyorsa
    if (shouldRedirectTo404 || error) {
        return <Navigate to="/404" />;
    }

    if (loading) return <Loader />;


    return (
        <>

            <div>
                <div className="sort-dropdown">

                    <select id="sortOrder" value={sort} onChange={handleSortChangeWithRedirect} >
                        <option value="newest">En Yeni</option>
                        <option value="price_asc">Artan Fiyata Göre</option>
                        <option value="price_desc">Azalan Fiyata Göre</option>
                    </select>
                </div></div>
            <div className='categories-container'>

                <div className='categories-list'>
                    <Sidebar />
                    <div className='categories-filter'>
                        <label>Fiyat</label>
                        <div className='price-inputs'>
                            <input
                                type="number"
                                placeholder='En Az'
                                value={minPrice}
                                onChange={handleMinPriceChange}
                            />


                            <input
                                type="number"
                                placeholder='En Çok'
                                value={maxPrice}
                                onChange={handleMaxPriceChange}
                            />
                            <FaSearch className="search-icon" onClick={filterByPrice} />
                        </div>

                    </div>
                </div>

                <div className="categories-product">

                    {filteredProducts.map(product => (
                        <a
                            key={`${product.id}`}
                            href={`/urun/${product.sef}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="categories-product-card"
                            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
                        >

                            <div className="categories-img-container">

                                <img src={getImageUrl(product.image.i1)} alt={product.baslik} onError={(e) => {
                                    e.target.onerror = null;
                                }} />
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleFavoriteToggle(e, product)
                                    }}
                                    className="favorite-icon"
                                >
                                    <FontAwesomeIcon
                                        icon={isFavorite(product) ? SolidHeart : RegularHeart}
                                        style={{ color: isFavorite(product) ? 'red' : 'gray', fontSize: window.innerWidth <= 768 ? '18px' : '24px' }}
                                    />
                                </div>
                            </div>
                            <div className="categories-responsive-info">
                                <h3 className="categories-responsive-name">
                                    {product.baslik}
                                </h3>
                                <div className="ratingg">
                                    {'★'.repeat(5)}
                                </div>
                                <div className="pricee">
                                    {product.fiyat && product.eski_fiyat !== product.fiyat ? (
                                        <>
                                            <h2 className="originall-price" style={{ textDecoration: 'line-through' }}>
                                                {product.eski_fiyat.toLocaleString('tr-TR')} TL
                                            </h2>
                                            <h2 className="discountedd-price">
                                                {product.fiyat.toLocaleString('tr-TR')} TL
                                            </h2>
                                        </>
                                    ) : (
                                        <h2 className="originall-price">
                                            {product.fiyat.toLocaleString('tr-TR')} TL
                                        </h2>
                                    )}
                                </div>
                            </div>

                        </a>
                    ))}
                </div>
            </div>


        </>
    );
};

export default CategoriesProduct;