import React, { useState, useEffect, useContext } from 'react';
import Iller from "../../il-ilce.json";
import Modal from 'react-modal';
import './DeliveryForm.css';
import { InstitutionalContext } from '../../context/InstitutionalContext';

const DeliveryForm = ({ 
  formData, 
  setFormData, 
  handleSubmit, 
  userLoading,
  referansKodu,
  termsAccepted,
  setTermsAccepted,
  salesTermsAccepted,
  setSalesTermsAccepted,
  deliveryTermsAccepted,
  setDeliveryTermsAccepted
}) => {
  const { fetchInstitutional } = useContext(InstitutionalContext);
  const [formErrors, setFormErrors] = useState({});
  const [iller, setIller] = useState([]);
  const [ilceler, setIlceler] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');
  const [termContent, setTermContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  
  useEffect(() => {
    setIller(Iller.iller);
  }, []);

  const termMap = {
    'kullanım-kosullari': 'kullanim-kosullari',
    'mesafeli-satis': 'mesafeli-satis-sozlesmesi',
    'teslimat-iade': 'teslimat-ve-iade'
  };

  const getTermsContent = async (sef) => {
    try {
      const mappedTerm = termMap[sef];      
      if (!mappedTerm) {
        throw new Error('Geçersiz terim');
      }
      
      const content = await fetchInstitutional(mappedTerm);

      if (!content || !content.baslik || !content.aciklama) {
        throw new Error('İçerik bulunamadı');
      }

      return (
        <div>
          <h2>{content.baslik}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.aciklama }} />
        </div>
      );
    } catch (error) {
      console.error('Sözleşme içeriği yüklenirken hata:', error);
      return (
        <div>
          <p>Sözleşme içeriği yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.</p>
        </div>
      );
    }
  };

  useEffect(() => {
    const loadTermContent = async () => {
      if (modalIsOpen && currentTerm) {
        setIsLoading(true);
        try {
          const content = await getTermsContent(currentTerm);
          setTermContent(content);
        } catch (error) {
          console.error('Sözleşme içeriği yüklenirken hata:', error);
          setTermContent(<div>Sözleşme içeriği yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.</div>);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadTermContent();
  }, [modalIsOpen, currentTerm]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'telefon') {
      handlePhoneInput(e);
      return;
    }
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const { value } = e.target;
    if (value.length === 0) {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else if (value.length === 1 && value !== "0") {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 11) {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue.substring(0, 11) }));
      } else {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue }));
      }
    }
  };

  const formatPhoneNumber = (number) => {
    return number.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  };

  const handleIlChange = (event) => {
    const ilSecimi = event.target.value;
    setFormData(prev => ({ ...prev, il: ilSecimi, ilce: '' }));
    const secilenIl = iller.find((il) => il.il_adi === ilSecimi);
    setIlceler(secilenIl ? secilenIl.ilceler : []);
  };

  return (
    <div className='order-summary-second'>
      <form onSubmit={handleSubmit} className="checkout-form">
        <div className="checkout-form-group">
          <div className='siparis-adres-baslik'>
            <h3>Teslimat Bilgileri</h3>
          </div>
        </div>

        <div className="checkout-form-group">
          <div>
            <label>Ad*</label>
            <input
              type="text"
              name="ad"
              value={formData.ad}
              onChange={handleInputChange}
              placeholder="Ad*"
              required
            />
            {formErrors.ad && <span className="error">{formErrors.ad}</span>}
          </div>
          <div>
            <label>Soyad*</label>
            <input
              type="text"
              name="soyad"
              value={formData.soyad}
              onChange={handleInputChange}
              placeholder="Soyad*"
              required
            />
            {formErrors.soyad && <span className="error">{formErrors.soyad}</span>}
          </div>
        </div>

        <div className="checkout-form-group">
          <div>
            <label>Email*</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email*"
              required
            />
            {formErrors.email && <span className="error">{formErrors.email}</span>}
          </div>
          <div>
            <label>Telefon*</label>
            <input
              type="tel"
              name="telefon"
              value={formatPhoneNumber(formData.telefon)}
              onChange={handleInputChange}
              placeholder="Telefon*"
              maxLength="14"
              required
            />
            {formErrors.telefon && <span className="error">{formErrors.telefon}</span>}
          </div>
        </div>

        <div className="checkout-form-group">
          <select
            name="il"
            value={formData.il}
            onChange={handleIlChange}
            required
          >
            <option value="">Lütfen Bir İl Seçiniz</option>
            {iller.map((il, index) => (
              <option key={index} value={il.il_adi}>
                {il.il_adi}
              </option>
            ))}
          </select>
          {formErrors.il && <span className="error">{formErrors.il}</span>}

          <select
            name="ilce"
            value={formData.ilce}
            onChange={handleInputChange}
            required
            disabled={!formData.il}
          >
            <option value="">Lütfen Bir İlçe Seçiniz</option>
            {ilceler.map((ilce, index) => (
              <option key={index} value={ilce.ilce_adi}>
                {ilce.ilce_adi}
              </option>
            ))}
          </select>
          {formErrors.ilce && <span className="error">{formErrors.ilce}</span>}
        </div>

        <div className="checkout-form-group">
          <label>Sipariş Adresiniz*</label>
          <textarea
            name="adres"
            value={formData.adres}
            onChange={handleInputChange}
            placeholder="Sipariş Adresiniz"
            required
          />
          {formErrors.adres && <span className="error">{formErrors.adres}</span>}
        </div>

        <div className="checkout-form-group">
          <label>Sipariş Notunuz</label>
          <textarea
            name="siparis_notu"
            value={formData.siparis_notu}
            onChange={handleInputChange}
            placeholder="Sipariş Notunuz"
          />
        </div>

        <div className="payment-method-options">
          <h3>Ödeme Yöntemi</h3>
          <div className="payment-radio-container">
            <div className="payment-radio">
              <input
                type="radio"
                id="creditCard"
                name="paymentType"
                value="creditCard"
                checked={formData.paymentType === 'creditCard'}
                onChange={handleInputChange}
              />
              <label htmlFor="creditCard">Kredi Kartı ile Ödeme</label>
            </div>
            <div className="payment-radio">
              <input
                type="radio"
                id="eft"
                name="paymentType"
                value="eft"
                checked={formData.paymentType === 'eft'}
                onChange={handleInputChange}
              />
              <label htmlFor="eft">Havale/EFT ile Ödeme</label>
            </div>
          </div>
        </div>

        {referansKodu && (
          <div className="checkout-form-group ref-code" style={{ display: 'none' }}>
            <label>Referans Kodu</label>
            <input
              type="hidden"
              name="referansKodu"
              value={referansKodu}
            />
            <p className="referans-info">Bu sepet sizinle paylaşıldı. Referans kodu otomatik olarak kaydedilmiştir.</p>
          </div>
        )}

        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id="kullanim-kosullari"
            checked={termsAccepted}
            onChange={() => setTermsAccepted(!termsAccepted)}
          />
          <label htmlFor="kullanim-kosullari">
            <a href="#" onClick={(e) => {
              e.preventDefault();
              setCurrentTerm('kullanım-kosullari');
              setModalIsOpen(true);
            }}>Kullanım Koşulları</a> sözleşmesini okudum ve kabul ediyorum.
          </label>
        </div>

        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id="mesafeli-satis"
            checked={salesTermsAccepted}
            onChange={() => setSalesTermsAccepted(!salesTermsAccepted)}
          />
          <label htmlFor="mesafeli-satis">
            <a href="#" onClick={(e) => {
              e.preventDefault();
              setCurrentTerm('mesafeli-satis');
              setModalIsOpen(true);
            }}>Mesafeli Satış</a> sözleşmesini okudum ve kabul ediyorum.
          </label>
        </div>

        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id="teslimat-iade"
            checked={deliveryTermsAccepted}
            onChange={() => setDeliveryTermsAccepted(!deliveryTermsAccepted)}
          />
          <label htmlFor="teslimat-iade">
            <a href="#" onClick={(e) => {
              e.preventDefault();
              setCurrentTerm('teslimat-iade');
              setModalIsOpen(true);
            }}>Teslimat ve İade</a> sözleşmesini okudum ve kabul ediyorum.
          </label>
        </div>

        <button type="submit" className='button-apply'>Siparişi Tamamla</button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentTerm('');
          setTermContent(null);
        }}
        contentLabel="Sözleşme Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button
          className="close-button"
          onClick={() => {
            setModalIsOpen(false);
            setCurrentTerm('');
            setTermContent(null);
          }}
        >
          ×
        </button>
        {isLoading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <p>Sözleşme içeriği yükleniyor...</p>
          </div>
        ) : (
          termContent
        )}
      </Modal>
    </div>
  );
};

export default DeliveryForm; 