import React from 'react';
import './EmptyCart.css';

const EmptyCart = () => {
  return (
    <div className='card-container'>
      <div className='empty-cart'>
        <div className='empty-cart-icon'>
          <svg width="80" height="80" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001" stroke="#ccc" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H8C3.73 8 3.03 9.99 3.3 12.43L4.05 18.43C4.26 20.39 4.98 22 9 22Z" stroke="#ccc" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M15.4955 12H15.5045" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.49451 12H8.50349" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <h2>Sepetiniz Boş</h2>
        <p>Sepetinizde ürün bulunmamaktadır.</p>
        <p className="empty-cart-message">Ürünleri sepetinize ekleyerek alışverişe başlayabilirsiniz.</p>
        <a href="/" className="shop-now-button">
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px' }}>
            <path d="M8.39999 6.5H15.6C19 6.5 19.34 8.09 19.57 10.03L20.47 17.53C20.76 19.99 20 22 16.5 22H7.50999C3.99999 22 3.23999 19.99 3.53999 17.53L4.44999 10.03C4.66999 8.09 4.99999 6.5 8.39999 6.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 8V4.5C8 3 9 2 10.5 2H13.5C15 2 16 3 16 4.5V8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.41 17.03H8" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Alışverişe Başla
        </a>
      </div>
    </div>
  );
};

export default EmptyCart; 