import React from 'react';
import CouponSection from './CouponSection';
import './OrderSummary.css';

const OrderSummary = ({ total, shippingTotal, appliedCoupon, onCouponApply, onCouponRemove }) => {
  const calculateFinalTotal = () => {
    const subtotal = parseFloat(total);
    const shipping = parseFloat(shippingTotal);
    const discount = appliedCoupon ? appliedCoupon.amount : 0;
    return (subtotal + shipping - discount).toLocaleString('tr-TR', { 
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0 
    });
  };

  return (
    <div className="order-summary-first">
      <div className="summary-item">
        <span>Ara Tutar</span>
        <span>{total.toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })} TL</span>
      </div>
      
      <div className="summary-item">
        <span>Kargo Ücreti</span>
        <span>{shippingTotal}TL</span>
      </div>
      
      {/* <CouponSection onCouponApply={onCouponApply} onCouponRemove={onCouponRemove} /> */}
      
      {/* {appliedCoupon && (
        <div className="summary-item coupon-discount">
          <span>Kupon İndirimi</span>
          <span>-{appliedCoupon.amount} TL</span>
        </div>
      )} */}

      <div className="summary-item-total">
        <span>Toplam Tutar</span>
        <span>{calculateFinalTotal()} TL</span>
      </div>
    </div>
  );
};

export default OrderSummary; 