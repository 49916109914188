import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import { useToast } from '../../context/ToastContext';


const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = 'Email zorunludur';
    }
    if (!formData.password) {
      formErrors.password = 'Şifre zorunludur';
    }
    return formErrors;
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    try {

      const tkn = localStorage.getItem('tkn');
      const token = localStorage.getItem('token');
      const response = await fetch(`https://api.bikuyum.com/api/login`, {
        method: 'POST',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',

        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        localStorage.setItem('token', data.success);
        showToast('Başarı ile giriş yapıldı', 'success');
        navigate("/");
        // window.location.reload();
      } else {
        showToast(data.message || 'Mail adresiniz veya şifreniz hatalı.', 'error');
        setErrors({ form: data.message || 'Mail adresiniz veya şifreniz hatalı.' });
      }
    } catch (error) {
      setErrors({ form: 'Sunucu ile bağlantı kurulamadı.' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="login-container">
      <div className="login-baslik">
        <h3 className='login-title'>Giriş Yap</h3>
        <p>Giriş yaparak siparişlerinizi daha rahat takip edebilirsiniz</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-login">
          <label>Email *</label>
          <input

            type="email"
            name="email"
            placeholder="email@gmail.com"
            value={formData.email}
            onChange={handleChange}
            required

          />
          {errors.email && <p className="error">{errors.email}</p>}
        </div>
        <div className="form-login">
          <label>Şifreniz *</label>
          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="********"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <span
              className="password-toggle-icon"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <i className="fa fa-eye-slash" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-eye" aria-hidden="true"></i>
              )}
            </span>
          </div>
          {errors.password && <p className="error">{errors.password}</p>}
        </div>
        {errors.form && <p className="error form-error">{errors.form}</p>}
        <button type="submit" className="login-bttn" disabled={loading}>Giriş Yap</button>

      </form>
      <div className="login-links">
        <p>Henüz bir hesabınız yok mu? <a href="/register">Kayıt Ol</a></p>
        <p>Şifrenizi mi Unuttunuz? <a href="/forgotpassword">Şifremi Sıfırla</a></p>
      </div>
    </div>
  );
};

export default Login;