import React, { useContext, useEffect, useState, useRef } from 'react';
import { ProductContext } from '../context/ProductContext.js';
import { useNavigate } from 'react-router-dom';

import "../assest/SearchBar.css";

import { IoIosSearch } from "react-icons/io";


const Searchbar = () => {
  const { searchProducts, state } = useContext(ProductContext);
  const [query, setQuery] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const searchbarRef = useRef(null);

  // kullanıcı yazmayı bitirene kadar arama işlemini geciktirerek gereksiz api çağrılarını önler
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearch = React.useCallback(
    debounce((value) => {
      if (value.trim() !== '') {
        searchProducts(value);
      }
    }, 300),
    []
  );

  // API yanıt verdiğinde yükleme durumunu güncelle
  useEffect(() => {
    if (state.searchs !== undefined) {
      setIsLoading(false);
    }
  }, [state.searchs])

  // Herhangi bir yere tıklandığında dropdown'ı kapatma
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (query.trim() !== '') {
      navigate(`/search?query=${query}`, { replace: true });
      setIsDropdownVisible(false);
    }
  };

  // kullanıcı arama yaparken arama sonuçlarını gösterme
  const handleInputChanges = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() !== '') {
      setIsLoading(true);
      debouncedSearch(value);
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(false);
      setIsLoading(false);
    }
  };

  // Benzersiz sonuçları api sonuçlarından filtreleme
  const uniqueSearchResults = React.useMemo(() => {
    if (!state.searchs) return [];
    // contextten aldığımız unique sonuçların 10 tanesini getiriyoruz
    return state.searchs.slice(0, 10);
  }, [state.searchs]);

  try {
    fbq('track', 'Search');
  } catch (error) {
    console.error('Facebook Pixel izleme hatası:', error);
  }

  return (
    <div className="searchbar" ref={searchbarRef}>
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Binlerce ürün arasından ürün arayabilirsiniz..."
          aria-label="search"
          value={query}
          onChange={handleInputChanges}
          className="search-input"
        />
        <button type="submit" aria-label="search" className="search-button">
          <IoIosSearch size={20} />
        </button>
      </form>
      {isDropdownVisible && (
        <div className="search-results">
          {uniqueSearchResults.length > 0 ? (
            uniqueSearchResults.map((product) => (
              <div key={product.id} className="search-results-product">
                <a href={`/urun/${product.sef}`}>
                  <p>{product.baslik}</p></a>
              </div>
            ))
          ) : (
            isLoading ? (
              <p className='search-results-loading'>Sonuçlar Yükleniyor...</p>
            ) : (
              <p className='no-results'>Sonuç Bulunamadı</p>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Searchbar;