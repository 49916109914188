import React, { createContext, useReducer, useEffect } from "react";

const initialState = {
  institutionals: null,
  institutionalsCategori: [],

}
const institutionalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_INSTITUTIONAL':
      return { ...state, institutionals: action.payload };
    case 'FETCH_INSTITUTIONAL_CATEGORİ_SUCCESS':
      return { ...state, institutionalsCategori: action.payload };

    default:
      return state;
  }
}

export const InstitutionalContext = createContext();

export const InstitutionalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(institutionalReducer, initialState);


  const fetchInstitutional = async (sef) => {
    try {
      const token = localStorage.getItem('token');
      const tkn = localStorage.getItem('tkn');
      const response = await fetch(`https://api.bikuyum.com/api/page?page=${sef}`, {
        method: 'GET',
        headers: {
          'Authorization': `${token}`,
          'Tkn': `${tkn}`,
          'Content-Type': 'application/json',

        },

      });
      const institutionals = await response.json();
      dispatch({ type: 'SET_SELECTED_INSTITUTIONAL', payload: institutionals });
      return institutionals;
    } catch (error) {
      console.error('Error fetching contracts:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchInstitutionalCategori = async () => {
      try {
        const token = localStorage.getItem('token');
        const tkn = localStorage.getItem('tkn');
        const response = await fetch('https://api.bikuyum.com/api/pages', {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Tkn': `${tkn}`,
            'Content-Type': 'application/json',

          },

        });
        const data = await response.json();
        dispatch({ type: 'FETCH_INSTITUTIONAL_CATEGORİ_SUCCESS', payload: data });
      } catch (error) {
        console.error('Error fetching contracts:', error);
      }
    };
    fetchInstitutionalCategori();
  }, []);


  return (<InstitutionalContext.Provider value={{ ...state, fetchInstitutional }}>
    {children}
  </InstitutionalContext.Provider>

  );
};