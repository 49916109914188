import React from 'react';
import { GoTrash } from "react-icons/go";
import './CartItem.css';

const CartItem = ({ product, deleteCard, getImageUrl }) => {
  return (
    <div className='card-item'>
      <div className='card-product-header'>
        <span>Satıcı: <a href={`/${product.magaza_sef}/all`}><span>{product.magaza_adi}</span></a></span>
      </div>
      <hr className='hr' />
      <div className='card-product-content'>
        <div className='card-product-info'>
          <a href={`/urun/${product.sef}`}>
            <img src={getImageUrl(product.image.i1)} alt={product.baslik} className='product-imagee' />
          </a>
          <a href={`/urun/${product.sef}`}>
            <p>{product.baslik}</p>
            {product.size !== 0 && product.size !== 15 && (
              <span>({product.size} numara 150 TL fiyat farkı eklenmiştir)</span>
            )}
          </a>
        </div>
        <div className='card-quantity'>
          <p>{product.quantity || 1}</p>
        </div>
        <div className='card-price'>
          <p>{(product.fiyat !== undefined ? product.fiyat : 0).toLocaleString('tr-TR', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}TL</p>
        </div>
        <div className='card-remove'>
          <button className="delete-button" onClick={() => deleteCard(product.cart_id)}><GoTrash size={18} /></button>
        </div>
      </div>
    </div>
  );
};

export default CartItem; 