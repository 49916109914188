import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./OpenStore.css";
import axios from "axios";
import { useToast } from "../../context/ToastContext";
import { InstitutionalContext } from "../../context/InstitutionalContext";
import Modal from 'react-modal';

const OpenStore = () => {
  const navigate = useNavigate();
  const [consent, setConsent] = useState(false);
  const [formData, setFormData] = useState({
    ad: '',
    soyad: '',
    telefon: '',
    email: '',
    sifre: '',
    sifreOnay: '',
    sozlesmeOnay: false,
  });

  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  // Modal ile ilgili state'ler
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');
  const [termContent, setTermContent] = useState(null);

  // InstitutionalContext'ten fetchInstitutional fonksiyonunu al
  const { fetchInstitutional } = useContext(InstitutionalContext);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Sözleşme içeriğini getirme işlemi
  const getTermsContent = async (sef) => {
    try {
      const content = await fetchInstitutional(sef);

      if (!content || !content.baslik || !content.aciklama) {
        throw new Error('İçerik bulunamadı');
      }

      return (
        <div>
          <h2>{content.baslik}</h2>
          <div dangerouslySetInnerHTML={{ __html: content.aciklama }} />
        </div>
      );
    } catch (error) {
      console.error('Sözleşme içeriği yüklenirken hata oluştu:', error);
      showToast('Sözleşme içeriği yüklenirken bir hata oluştu.', 'error');
      return <div>İçerik yüklenirken bir hata oluştu.</div>;
    }
  };

  // Modal içeriğini yükleme
  useEffect(() => {
    async function loadContent() {
      if (modalIsOpen && currentTerm) {
        const content = await getTermsContent(currentTerm);
        setTermContent(content);
      } else {
        setTermContent(null);
      }
    }
    loadContent();
  }, [modalIsOpen, currentTerm]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.sifre !== formData.sifreOnay) {
      alert("Şifreler uyuşmuyor.");
      return;
    }


    if (!formData.sozlesmeOnay) {
      alert("Lütfen sözleşmeyi kabul edin.");
      return;
    }

    const guest = {
      name: formData.ad,
      surname: formData.soyad,
      email: formData.email,
      password: formData.sifre,
      passwordConfirm: formData.sifreOnay,
    };

    axios
      .post('https://api.bikuyum.com/api/register', guest)
      .then((res) => {
        console.log(res.data);
        setTimeout(() => {
          alert("İşlem Başarılı");
          navigate("/storeInformation");
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        alert("Kayıt işlemi sırasında bir hata oluştu.");
      });
  };


  return (
    <div className="main">
      <div className="form-container">
        <h2>Hızlı Kayıt Ol</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input
              type="text"
              name="ad"
              placeholder="Ad"
              value={formData.ad}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="soyad"
              placeholder="Soyad"
              value={formData.soyad}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="tel"
              name="telefon"
              placeholder="Telefon Numarası"
              value={formData.telefon}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="email"
              name="email"
              placeholder="E-Posta Adresi"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="password"
              name="sifre"
              placeholder="Şifre"
              value={formData.sifre}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row">
            <input
              type="password"
              name="sifreOnay"
              placeholder="Şifre Tekrarı"
              value={formData.sifreOnay}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-checkbox store-check">
            <input
              type="checkbox"
              id="sozlesme-onay"
              onChange={() => setConsent(!consent)}
              required
            />
            <label htmlFor="sozlesme-onay" className="consent-label">
              <a href="#" onClick={(e) => {
                e.preventDefault();
                setCurrentTerm('dijital-magaza-kiralama-sozlesmesi');
                setModalIsOpen(true);
              }}>Dijital Mağaza Kiralama Sözleşmesi </a> okudum ve kabul ediyorum.
            </label>
          </div>
          <button type="submit" className="kayit-btn">Kayıt Ol</button>
        </form>
      </div>
      <div className="store-login">
        <h2>Zaten Mağazan Var mı?</h2>
        <p>Giriş yap butonuna tıklayarak giriş yapabilirsiniz.</p>

        <div className="features">
          <div className="feature-item">
            <div className="icon">🔒</div>
            <p>Güvenli Ödeme, Keyifli Alışveriş</p>
          </div>
          <div className="feature-item">
            <div className="icon">🏠</div>
            <p>Ücretsiz ve kolay mağaza açma şansı</p>
          </div>
          <div className="feature-item">
            <div className="icon">🚀</div>
            <p>Hızlı ve güvenli alışverişin yeni adresi</p>
          </div>
        </div>
        <a href="/login"><button className="login-btn">Mağaza Girişi Yap</button></a>
      </div>

      {/* Sözleşme Modalı */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setCurrentTerm('');
          setTermContent(null);
        }}
        contentLabel="Sözleşme Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button
          className="close-button"
          onClick={() => {
            setModalIsOpen(false);
            setCurrentTerm('');
            setTermContent(null);
          }}
        >
          ×
        </button>
        {termContent || <div>Yükleniyor...</div>}
      </Modal>
    </div>

  );
};
export default OpenStore;