import React, { useState, useEffect, useContext } from 'react';
import "./Card.css";
import { useNavigate, useLocation } from 'react-router-dom';
import cardd from "./card.png";
import { CardContext } from "../../context/CardContext.js";
import Iller from "../../il-ilce.json";
import { UserContext } from '../../context/UserContext.js';
import Modal from 'react-modal';
import { useImage } from "../../context/ImageContext.js";
import Loader from '../../components/Loader.js';
import { GoTrash } from "react-icons/go";
import { FaShare } from "react-icons/fa";
import { useToast } from '../../context/ToastContext.js';

// Komponentleri import ediyoruz
import EmptyCart from '../../components/card/EmptyCart';
import CartItem from '../../components/card/CartItem';
import OrderSummary from '../../components/card/OrderSummary';
import DeliveryForm from '../../components/card/DeliveryForm';
import ShareModal from '../../components/card/ShareModal';

Modal.setAppElement('#root');

const Card = () => {
  const { getImageUrl } = useImage();
  const navigate = useNavigate();
  const location = useLocation();
  const { formData: userData, loading: userLoading } = useContext(UserContext);
  const { state, shippingTotal, cart, total, loading, deleteCard, shareCart, shareCode } = useContext(CardContext);
  const { showToast } = useToast();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [referansKodu, setReferansKodu] = useState('');
  
  // userLoading değişkeni ile verinin hazır olup olmadığını kontrol ediyoruz
  const [formData, setFormData] = useState({
    ad: '',
    soyad: '',
    email: '',
    telefon: '05',
    il: '',
    ilce: '',
    adres: '',
    siparis_notu: '',
    paymentType: 'creditCard'
  });

  const [formErrors, setFormErrors] = useState({});
  const [iller, setIller] = useState([]);
  const [ilceler, setIlceler] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [salesTermsAccepted, setSalesTermsAccepted] = useState(false);
  const [deliveryTermsAccepted, setDeliveryTermsAccepted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentTerm, setCurrentTerm] = useState('');
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [generatedShareCode, setGeneratedShareCode] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);

  // Hizmet modalı için state'ler
  const [serviceModalIsOpen, setServiceModalIsOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [selectedServices, setSelectedServices] = useState({});

  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState('');
  const [couponSuccess, setCouponSuccess] = useState('');
  const [appliedCoupon, setAppliedCoupon] = useState(null);

  useEffect(() => {
    setIller(Iller.iller);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const tkn = localStorage.getItem('tkn');
    setIsAuthenticated(!!token && !!tkn);
    
    // Kullanıcı adreslerini localStorage'dan al
    const savedAddresses = localStorage.getItem('userAddresses');
    if (savedAddresses) {
      const addresses = JSON.parse(savedAddresses);
      setUserAddresses(addresses);
      // Eğer adres varsa ilk adresi varsayılan olarak seç
      if (addresses.length > 0) {
        setSelectedAddressId(addresses[0].id);
      }
    }

    // UserContext'ten veriler geldiğinde formData'yı güncelleyen useEffect
    if (userData && !userLoading) {
      setFormData(prevFormData => ({
        ...prevFormData,
        ad: userData.ad || prevFormData.ad,
        soyad: userData.soyad || prevFormData.soyad,
        email: userData.email || prevFormData.email,
        telefon: userData.telefon || prevFormData.telefon || '05',
        adres: userData.adres || prevFormData.adres
      }));
    }
  }, []);
  
  // Seçilen adresi forma uygulayan useEffect
  useEffect(() => {
    if (selectedAddressId && userAddresses.length > 0) {
      const selectedAddress = userAddresses.find(addr => addr.id === selectedAddressId);
      if (selectedAddress) {
        // İl ve ilçe dahil tüm adres bilgilerini forma uygula
        setFormData(prev => ({
          ...prev,
          adres: selectedAddress.addressText,
          il: selectedAddress.il || "",
          ilce: selectedAddress.ilce || ""
        }));
        
        // İl seçilmişse ilçeleri güncelle
        if (selectedAddress.il) {
          const secilenIl = iller.find((il) => il.il_adi === selectedAddress.il);
          setIlceler(secilenIl ? secilenIl.ilceler : []);
        }
      }
    }
  }, [selectedAddressId, userAddresses, iller]);

  useEffect(() => {
    if (location.state?.directCheckout && cart.length > 0) {
      handleSubmit({ preventDefault: () => { } });
    }
  }, [cart, location.state]);

  useEffect(() => {
    // Cookie'den referans kodunu al
    const getCookieValue = (name) => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return '';
    };

    const shareRef = getCookieValue('share_ref');
    if (shareRef) {
      setReferansKodu(shareRef);
    }
  }, []);



  const validateForm = () => {
    const errors = {};
    if (!formData.ad.trim()) errors.ad = 'Ad alanı zorunludur';
    if (!formData.soyad.trim()) errors.soyad = 'Soyad alanı zorunludur';
    if (!formData.email.trim()) errors.email = 'Email alanı zorunludur';
    if (!formData.telefon || formData.telefon.length < 11) errors.telefon = 'Geçerli bir telefon numarası giriniz';
    if (!formData.il) errors.il = 'İl seçimi zorunludur';
    if (!formData.ilce) errors.ilce = 'İlçe seçimi zorunludur';
    if (!formData.adres.trim()) errors.adres = 'Adres alanı zorunludur';
    return errors;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'telefon') {
      handlePhoneInput(e);
      return;
    }

    if (name === 'paymentType') {
      setFormData(prev => ({ ...prev, paymentType: value }));
      return;
    }

    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handlePhoneInput = (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else if (value.length === 1 && value !== "0") {
      setFormData(prev => ({ ...prev, telefon: '05' }));
    } else {
      const sanitizedValue = value.replace(/[^0-9]/g, '');
      if (sanitizedValue.length > 11) {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue.substring(0, 11) }));
      } else {
        setFormData(prev => ({ ...prev, telefon: sanitizedValue }));
      }
    }
  };

  const formatPhoneNumber = (number) => {
    return number.replace(/(\d{4})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
  };

  const handleIlChange = (event) => {
    const ilSecimi = event.target.value;
    setFormData(prev => ({ ...prev, il: ilSecimi, ilce: '' }));

    const secilenIl = iller.find((il) => il.il_adi === ilSecimi);
    setIlceler(secilenIl ? secilenIl.ilceler : []);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!termsAccepted || !salesTermsAccepted || !deliveryTermsAccepted) {
      showToast("Lütfen tüm sözleşmeleri kabul edin.", "info");
      return;
    }

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      window.scrollTo(0, 0);
      return;
    }

    try {
      navigate("/payment", {
        state: {
          total: parseFloat(total) + parseFloat(shippingTotal),
          customerForm: formData,
          cart: cart,
          referansKodu
        }
      });
    } catch (error) {
      console.error('Hata:', error);
      showToast('İşlem sırasında bir hata oluştu', 'error');
    }
  };

  const handleCouponApply = async (code) => {
    try {
      // Burada kupon doğrulama API'si çağrılacak
      // Şimdilik mock bir response dönüyoruz
      setAppliedCoupon({
        code: code,
        amount: 100
      });
      return { success: true };
    } catch (error) {
      return { success: false };
    }
  };

  const handleCouponRemove = () => {
    setAppliedCoupon(null);
  };

  const handleShareCart = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    
    try {
      const code = await shareCart();
      if (code) {
        const url = `${window.location.origin}/shared-cart/${code}`;
        setGeneratedShareCode(code);
        setShareUrl(url);
        setShareModalIsOpen(true);
      } else {
        showToast('Sepet paylaşma işlemi sırasında bir hata oluştu', 'error');
      }
    } catch (error) {
      showToast('Bir hata oluştu', 'error');
    }
  };

  // Hizmet ekleme fonksiyonu
  // const handleAddService = (product) => {
  //   setCurrentProduct(product);
  //   setServiceModalIsOpen(true);
  // };

  // Servislerin seçilmesi için fonksiyon
  // const handleServiceSelection = (serviceType, value) => {
  //   setSelectedServices(prev => ({
  //     ...prev,
  //     [currentProduct.cart_id]: {
  //       ...prev[currentProduct.cart_id],
  //       [serviceType]: value
  //     }
  //   }));
  // };

  const copyToClipboard = () => {
    try {
      // Yardımcı input elementi oluştur
      const textArea = document.createElement('textarea');
      textArea.value = shareUrl;
      // Görünmez yap
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      
      // Seç ve kopyala
      textArea.focus();
      textArea.select();
      
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      
      if (successful) {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
      } else {
        // execCommand başarısız olursa modern API'yi dene
        navigator.clipboard.writeText(shareUrl)
          .then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 3000);
          })
          .catch(err => {
            console.error('Kopyalama hatası:', err);
            showToast('Kopyalama işlemi başarısız oldu.', 'error');
          });
      }
    } catch (err) {
      console.error('Kopyalama hatası:', err);
      showToast('Kopyalama işlemi başarısız oldu.', 'error');
    }
  };

  const handleAddressSelection = (e) => {
    const addressId = parseInt(e.target.value);
    setSelectedAddressId(addressId);
  };

  if (cart.length === 0) {
    return <EmptyCart />;
  }

  if (loading) return <Loader />;

  return (
    <div className="checkout-page">
      <div className='card-product'>
        <div className='card-title'>
          <img src={cardd} alt="card" />
          <span>Sepetim</span>
          <button
            className="share-cart-button"
            onClick={handleShareCart}
            title="Sepeti Paylaş"
          >
            <FaShare /> Sepeti Paylaş
          </button>
        </div>
        
        <div className="product-listt">
          {cart.map((product, index) => (
            <CartItem 
              key={index}
              product={product}
              deleteCard={deleteCard}
              getImageUrl={getImageUrl}
            />
          ))}
        </div>
      </div>

      <div className="order-summary">
        <OrderSummary 
          total={total}
          shippingTotal={shippingTotal}
          // appliedCoupon={appliedCoupon}
          // onCouponApply={handleCouponApply}
          // onCouponRemove={handleCouponRemove}
        />

        <DeliveryForm 
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
          userLoading={userLoading}
          referansKodu={referansKodu}
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
          salesTermsAccepted={salesTermsAccepted}
          setSalesTermsAccepted={setSalesTermsAccepted}
          deliveryTermsAccepted={deliveryTermsAccepted}
          setDeliveryTermsAccepted={setDeliveryTermsAccepted}
s        />
      </div>

      <ShareModal 
        isOpen={shareModalIsOpen}
        onClose={() => setShareModalIsOpen(false)}
        shareUrl={shareUrl}
        generatedShareCode={generatedShareCode}
      />
    </div>
  );
};

export default Card;